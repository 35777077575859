import { cn } from '@mntn-dev/ui-utilities'
import type { CSSProperties, PropsWithChildren } from 'react'

import type { AnyValue } from '@mntn-dev/utility-types'
import { type TestIds, getTestProps } from '../../utils'

// needed to allow dynamic row identifier for data test ids
type RowType = {
  [key: string]: AnyValue
}

type TableRowProps = PropsWithChildren<
  {
    className?: string
    onClick?: (row: RowType) => void
    style?: CSSProperties
  } & TestIds
>

const TableRow = ({
  className = '',
  children,
  onClick,
  dataTestId,
  dataTrackingId,
  style,
}: TableRowProps) => {
  return (
    <tr
      className={cn('group/table-row', onClick && 'cursor-pointer', className)}
      onClick={onClick}
      style={style}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </tr>
  )
}

export { TableRow, type TableRowProps, type RowType }
