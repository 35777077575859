'use client'

import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { AnimatePresence, easeOut, motion } from 'framer-motion'
import type { PropsWithChildren } from 'react'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { useCollapsibleContext } from './use-collapsible.ts'
import { cn } from '@mntn-dev/ui-utilities'

type CollapsiblePanelProps = PropsWithChildren<{ className?: string } & TestIds>

const CollapsiblePanel = ({
  className,
  dataTestId,
  dataTrackingId,
  children,
}: CollapsiblePanelProps) => {
  const { isOpen } = useCollapsibleContext()
  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className={cn(
            'flex w-full items-center gap-4 self-stretch',
            themeTextColorMap.secondary,
            className
          )}
          {...getTestProps({ dataTestId, dataTrackingId })}
        >
          <motion.div
            initial={{ opacity: 0, y: -24, height: 0, overflow: 'hidden' }}
            animate={{ opacity: 1, y: 0, height: 'fit-content' }}
            exit={{ opacity: 0, y: -24, height: 0, overflow: 'hidden' }}
            transition={{ duration: 0.3, ease: easeOut }}
            /* @ts-ignore */ /* TODO: Fix this. TypeScript thinks `className` doesn't exist on `motion.div` */
            className="w-full origin-top"
          >
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export { CollapsiblePanel }
