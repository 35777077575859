import { z } from 'zod'

import { BrandDomainInsertModelSchema } from './brand.models.ts'
import {
  ExternalUserTypeSchema,
  UserDomainInsertModelSchema,
} from './user.models.ts'

export const OrganizationRegistrationModelSchema = BrandDomainInsertModelSchema

export const UserRegistrationModelSchema = UserDomainInsertModelSchema.pick({
  firstName: true,
  lastName: true,
  emailAddress: true,
}).extend({
  userType: ExternalUserTypeSchema,
})

export const RegistrationModelSchema = z.object({
  user: UserRegistrationModelSchema,
  organization: OrganizationRegistrationModelSchema,
})

export type RegistrationModel = z.infer<typeof RegistrationModelSchema>
