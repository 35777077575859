import { EnumBuilder } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  DollarsSchema,
  NonEmptyStringSchema,
  TimestampSchema,
} from './property.models.ts'
import {
  BidIdSchema,
  BidUrnSchema,
  ProjectIdSchema,
  TeamIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * BidStatus
 */
export const [BidStatuses, BidStatusSchema, BidStatusEnum] = EnumBuilder(
  'declined', // The agency has indicated they do not want to submit a bid
  'draft', // The agency is preparing the bid but has not yet submitted it
  'submitted', // The bid has been officially submitted for consideration
  'disqualified', // The bid was not submitted on time
  'accepted', // The bid was awarded, and the contract is being finalized
  'rejected', // The bids that were specifically rejected by the brand
  'unsuccessful' // The bids that were not specifically rejected, but did not win the bid
)
export type BidStatus = z.infer<typeof BidStatusSchema>

/**
 * BidDomainSelectModel
 */
export const BidDomainSelectModelSchema = z
  .object({
    bidId: BidIdSchema,
    bidUrn: BidUrnSchema,
    projectId: ProjectIdSchema,
    agencyTeamId: TeamIdSchema,
    status: BidStatusSchema,
    budget: DollarsSchema(),
    pitch: NonEmptyStringSchema(),
    submitted: z.object({
      userId: UserIdSchema,
      timestamp: TimestampSchema,
    }),
  })
  .partial({
    budget: true,
    pitch: true,
    submitted: true,
  })

export type BidDomainSelectModel = z.infer<typeof BidDomainSelectModelSchema>

/**
 * BidDomainInsertModel
 */
export const BidDomainInsertModelSchema = BidDomainSelectModelSchema.pick({
  bidId: true,
  projectId: true,
  agencyTeamId: true,
})
  .extend({ status: BidStatusSchema.extract(['draft', 'declined']) })
  .partial({ bidId: true })

export type BidDomainInsertModel = z.infer<typeof BidDomainInsertModelSchema>

/**
 * BidDomainUpdateModel
 */
export const BidDomainUpdateModelSchema = BidDomainSelectModelSchema.pick({
  status: true,
  submitted: true,
})
  .extend({
    budget: BidDomainSelectModelSchema.shape.budget.unwrap().nullable(),
    pitch: BidDomainSelectModelSchema.shape.pitch.unwrap().nullable(),
    submitted: BidDomainSelectModelSchema.shape.submitted.unwrap().nullable(),
  })
  .partial()

export type BidDomainUpdateModel = z.infer<typeof BidDomainUpdateModelSchema>

/**
 * BidDomainSelectModelFilters
 */
export const BidDomainSelectModelFiltersSchema = z
  .object({
    bidId: ModelFilterSchema(BidIdSchema),
    bidUrn: ModelFilterSchema(BidUrnSchema),
    projectId: ModelFilterSchema(ProjectIdSchema),
    agencyTeamId: ModelFilterSchema(TeamIdSchema),
    status: ModelFilterSchema(BidStatusSchema),
  })
  .partial()

export type BidDomainSelectModelFilters = z.infer<
  typeof BidDomainSelectModelFiltersSchema
>
