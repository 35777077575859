'use client'

import type { ThemeTextColor } from '@mntn-dev/ui-theme'
import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import { Heading } from '../../heading'

export type PageHeaderTitleProps = {
  title: string
  textColor?: ThemeTextColor
}

export const PageHeaderTitle = ({ title, textColor }: PageHeaderTitleProps) => {
  const { dataTestId, dataTrackingId } = useTestTagsContext()
  return (
    <Heading
      fontSize="4xl"
      fontWeight="bold"
      textColor={textColor || 'primary'}
      dataTestId={`${dataTestId}-title`}
      dataTrackingId={`${dataTrackingId}-title`}
      className="leading-tight header-title"
    >
      {title}
    </Heading>
  )
}
