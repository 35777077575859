'use client'

import type { PropsWithChildren, ReactNode } from 'react'

import type { ThemeTextColor } from '@mntn-dev/ui-theme'

import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import { Text } from '../../text/text.tsx'

export const PageHeaderSubtitle = ({
  subtitle,
  textColor = 'secondary',
}: PropsWithChildren<{ subtitle: ReactNode; textColor?: ThemeTextColor }>) => {
  const { dataTestId, dataTrackingId } = useTestTagsContext()
  return (
    <div className="flex gap-4">
      <Text
        textColor={textColor}
        fontSize="med"
        dataTestId={`${dataTestId}-subtitle`}
        dataTrackingId={`${dataTrackingId}-subtitle`}
      >
        {subtitle}
      </Text>
    </div>
  )
}
