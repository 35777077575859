import type { SvgIconProps } from './types/props.ts'

export const ArrowPathCircleIcon = (props: SvgIconProps) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      aria-label="Arrow Path Circle Icon"
      role="img"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0122 10.6736H16.5097V10.6727M7.48975 15.8241V13.3266M7.48975 13.3266L9.98728 13.3266M7.48975 13.3266L9.08097 14.9188C9.57647 15.4153 10.2053 15.7919 10.9315 15.9865C13.1332 16.5764 15.3961 15.2699 15.9861 13.0682M8.0133 10.932C8.60322 8.73034 10.8662 7.4238 13.0678 8.01372C13.7941 8.20831 14.4229 8.58495 14.9184 9.08139L16.5097 10.6727M16.5097 8.1761V10.6727"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
