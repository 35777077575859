import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

const SpotlightCardDetails = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={cn('flex-initial h-fit w-full', className)}>{children}</div>
)

export { SpotlightCardDetails }
