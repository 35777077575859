'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

import { type ThemeBackground, themeBackgroundMap } from '@mntn-dev/ui-theme'
import { type UseSizingProps, useSizing } from '../../hooks/use-sizing.ts'
import { type TestIds, getTestProps } from '../../utils/index.ts'
import { useSurfaceContext } from './use-surface.ts'

type SurfaceSectionProps = Readonly<
  PropsWithChildren<
    {
      className?: string
      backgroundColor?: ThemeBackground
      onClick?: () => void
    } & UseSizingProps &
      TestIds
  >
>

const SurfaceSection = ({
  backgroundColor = 'transparent',
  className,
  children,
  dataTestId,
  dataTrackingId,
  onClick,
  ...props
}: SurfaceSectionProps) => {
  const { hasSurfaceChildren, paddingClassName, setHasSurfaceChildren } =
    useSurfaceContext()

  const setRef = () => {
    if (!hasSurfaceChildren) {
      setHasSurfaceChildren(true)
    }
  }

  const sizing = useSizing({
    ...props,
  })

  return (
    <div
      ref={setRef}
      className={cn(
        paddingClassName,
        themeBackgroundMap[backgroundColor],
        ...Object.values(sizing),
        className
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export { SurfaceSection, type SurfaceSectionProps }
