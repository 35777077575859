'use client'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import type { PropsWithChildren } from 'react'
import { cn } from '@mntn-dev/ui-utilities'
import { Icon } from '../icon'
import { useCollapsibleContext } from './use-collapsible.ts'

type CollapsibleButtonProps = PropsWithChildren<
  { className?: string } & TestIds
>

const CollapsibleButton = ({
  className,
  children,
  dataTestId,
  dataTrackingId,
}: CollapsibleButtonProps) => {
  const { isOpen, toggleOpen } = useCollapsibleContext()

  return (
    <button
      type="button"
      className={cn(
        'flex w-full min-h-10 items-top gap-4 self-stretch justify-between',
        className
      )}
      onClick={toggleOpen}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
      <Icon
        name="ChevronDownIcon"
        color="info"
        size="sm"
        className={cn(isOpen && 'rotate-180', 'w-6 m-3')}
      />
    </button>
  )
}

export { CollapsibleButton }
