import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { type TestIds, getTestProps } from '../../../utils/testing.ts'

export type CardHeadingProps = PropsWithChildren<
  {
    className?: string
  } & TestIds
>
const CardHeading = ({ children, className, ...testIds }: CardHeadingProps) => (
  <div
    className={cn('flex flex-col gap-2', className)}
    {...getTestProps(testIds)}
  >
    {children}
  </div>
)

export { CardHeading }
