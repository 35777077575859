import {
  type UserDisplayModel,
  type UserDisplayModelComputedProperties,
  toUserDisplayModel,
} from '@mntn-dev/domain-types'
import type { SetOptional } from 'type-fest'

export type Person = UserDisplayModel & {
  avatarUrl?: string
}

export const toPerson = ({
  avatarUrl,
  ...person
}: SetOptional<Person, UserDisplayModelComputedProperties>) => ({
  ...toUserDisplayModel(person),
  avatarUrl,
})
