import type { ReactNode } from 'react'
import {
  BreadcrumbSimpleDivider,
  type BreadcrumbSimpleDividerProps,
} from './breadcrumb-simple-divider.tsx'

export type BreadcrumbDividerProps = Omit<
  BreadcrumbSimpleDividerProps,
  'divider'
> & {
  divider: ReactNode
}

export const BreadcrumbDivider = ({
  divider = '/',
  dividerSize = '2xl',
  dividerColor = 'info',
  className,
}: BreadcrumbDividerProps) => {
  if (typeof divider === 'string') {
    return (
      <BreadcrumbSimpleDivider
        divider={divider}
        dividerSize={dividerSize}
        dividerColor={dividerColor}
        className={className}
      />
    )
  }

  return divider
}
