import type { NonEmptyArray } from '@mntn-dev/utility-types'

export const isNonEmptyArray = <T>(array?: T[]): array is NonEmptyArray<T> => {
  return !!array && array.length > 0
}

export const asNonEmptyArrayOrUndefined = <T>(array: T[] | undefined) =>
  array && isNonEmptyArray(array) ? array : undefined

export function assertNonEmptyArray<T>(
  array: T[],
  message?: string
): asserts array is NonEmptyArray<T> {
  if (!isNonEmptyArray(array)) {
    throw new Error(message ?? 'Expected non-empty array')
  }
}

export const mapNonEmptyArray = <T, U>(
  tuple: Array<T>,
  map: (t: T) => U
): NonEmptyArray<U> => {
  assertNonEmptyArray(tuple)
  const [first, ...rest] = tuple
  return [map(first), ...rest.map(map)]
}
