import { cn } from '@mntn-dev/ui-utilities'
import { useMemo } from 'react'

import {
  type FontSize,
  type FontWeight,
  type ThemeActiveBackground,
  type ThemeActiveBorderColor,
  type ThemeActiveTextColor,
  type ThemeBackground,
  type ThemeBorderColor,
  type ThemeDisabledBackground,
  type ThemeDisabledBorderColor,
  type ThemeDisabledTextColor,
  type ThemeElevation,
  type ThemeHeightOption,
  type ThemeHoverBackground,
  type ThemeHoverBorderColor,
  type ThemeHoverGlow,
  type ThemeHoverTextColor,
  type ThemePadding,
  type ThemeTextColor,
  type ThemeWidthOption,
  elevationMap,
  fontSizeMap,
  fontWeightMap,
  getHeightClassName,
  getPaddingClassName,
  getWidthClassName,
  themeActiveBackgroundMap,
  themeActiveBorderColorMap,
  themeActiveTextColorMap,
  themeBackgroundMap,
  themeBorderColorMap,
  themeDisabledBackgroundMap,
  themeDisabledBorderColorMap,
  themeDisabledTextColorMap,
  themeHoverBackgroundMap,
  themeHoverBorderColorMap,
  themeHoverGlowMap,
  themeHoverTextColorMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import type { TupleToUnion } from 'type-fest'
import type { IconSize } from '../icon/index.ts'

const buttonColorThemeNames = [
  'default',
  'negative',
  'notice',
  'positive',
] as const
type ButtonColorTheme = TupleToUnion<typeof buttonColorThemeNames>

const buttonSizeNames = ['xs', 'sm', 'md', 'lg'] as const
type ButtonSize = TupleToUnion<typeof buttonSizeNames>

const buttonVariantNames = [
  'primary',
  'secondary',
  'text',
  'destructive',
  'radio',
] as const
type ButtonVariant = TupleToUnion<typeof buttonVariantNames>

type UseButtonStylesProps = {
  borderColor?: ThemeBorderColor
  iconColor?: ThemeTextColor
  textColor?: ThemeTextColor
  size: ButtonSize
  variant: ButtonVariant
  circular?: boolean
  colorTheme?: ButtonColorTheme
}

const sizeClassMap: Record<
  ButtonSize,
  {
    fontSize: FontSize
    fontWeight: FontWeight
    iconSize: IconSize
    height: Extract<ThemeHeightOption, ThemeWidthOption>
  } & Required<Pick<ThemePadding, 'paddingX'>>
> = {
  xs: {
    fontSize: 'sm',
    fontWeight: 'medium',
    iconSize: 'sm',
    height: '7',
    paddingX: '2',
  },
  sm: {
    fontSize: 'sm',
    fontWeight: 'medium',
    iconSize: 'sm',
    height: '10',
    paddingX: '4',
  },
  md: {
    fontSize: 'med',
    fontWeight: 'medium',
    iconSize: 'md',
    height: '12',
    paddingX: '4',
  },
  lg: {
    fontSize: 'med',
    fontWeight: 'medium',
    iconSize: 'md',
    height: '14',
    paddingX: '6',
  },
}

const getSizeClassName = (size: ButtonSize, circular?: boolean) => {
  const { fontSize, fontWeight, height, paddingX } = sizeClassMap[size]

  return cn(
    fontSizeMap[fontSize],
    fontWeightMap[fontWeight],
    getHeightClassName({ height }),
    {
      [getPaddingClassName({ paddingX }) ?? '']: !circular,
      [getWidthClassName({ width: height }) ?? '']: circular,
    }
  )
}

type VariantProps = {
  background?: ThemeBackground
  backgroundHover?: ThemeHoverBackground
  backgroundActive?: ThemeActiveBackground
  backgroundDisabled?: ThemeDisabledBackground
  border?: boolean
  borderColor?: ThemeBorderColor
  borderHoverColor?: ThemeHoverBorderColor
  borderActiveColor?: ThemeActiveBorderColor
  borderDisabledColor?: ThemeDisabledBorderColor
  textColor?: ThemeTextColor
  activeTextColor?: ThemeActiveTextColor
  hoverTextColor?: ThemeHoverTextColor
  disabledTextColor?: ThemeDisabledTextColor
  iconColor?: ThemeTextColor
  elevation?: ThemeElevation
  glowHover?: ThemeHoverGlow
}

const getPrimaryVariantPropsByColor = (
  colorTheme: ButtonColorTheme
): VariantProps => {
  switch (colorTheme) {
    case 'positive':
      return {
        background: 'positive',
        backgroundHover: 'positive',
        backgroundActive: 'positive',
        backgroundDisabled: 'positive-muted',
        border: true,
        borderColor: 'positive-bright',
        borderHoverColor: 'positive-extra-bright',
        borderActiveColor: 'positive-dark',
        borderDisabledColor: 'positive-extra-bright',
        textColor: 'primary-inverse',
        disabledTextColor: 'inverse',
        elevation: 'sm',
        glowHover: 'positive',
      }
    case 'negative':
      return {
        background: 'negative-medium',
        backgroundHover: 'negative-medium',
        backgroundActive: 'negative-dark',
        backgroundDisabled: 'negative-muted',
        border: true,
        borderColor: 'negative-bright',
        borderHoverColor: 'negative-bright',
        borderActiveColor: 'negative-dark',
        borderDisabledColor: 'negative-extra-bright',
        textColor: 'primary',
        disabledTextColor: 'primary',
        elevation: 'sm',
        glowHover: 'negative',
      }
    case 'notice':
      return {
        background: 'notice',
        backgroundHover: 'notice',
        backgroundActive: 'notice',
        backgroundDisabled: 'notice-muted',
        border: true,
        borderColor: 'notice-bright',
        borderHoverColor: 'notice-extra-bright',
        borderActiveColor: 'notice-dark',
        borderDisabledColor: 'notice-extra-bright',
        textColor: 'primary-inverse',
        disabledTextColor: 'inverse',
        elevation: 'sm',
        glowHover: 'notice',
      }
    default:
      return {
        background: 'info',
        backgroundHover: 'cta',
        backgroundActive: 'cta',
        backgroundDisabled: 'info',
        border: true,
        borderColor: 'medium',
        borderHoverColor: 'cta',
        borderActiveColor: 'cta',
        borderDisabledColor: 'cta',
        textColor: 'primary-inverse',
        disabledTextColor: 'inverse',
        elevation: 'sm',
        glowHover: 'info',
      }
  }
}

const getRadioVariantPropsByColor = (
  colorTheme: ButtonColorTheme
): VariantProps => {
  switch (colorTheme) {
    case 'positive':
      return {
        background: 'secondary',
        backgroundHover: 'positive-bright-muted',
        backgroundActive: 'secondary',
        backgroundDisabled: 'secondary',
        border: true,
        borderColor: 'positive-bright',
        borderHoverColor: 'positive-extra-bright',
        borderActiveColor: 'positive-dark',
        borderDisabledColor: 'positive-extra-bright',
        textColor: 'primary',
        disabledTextColor: 'primary',
        elevation: 'sm',
        glowHover: 'positive-inner',
      }
    case 'notice':
      return {
        background: 'secondary',
        backgroundHover: 'notice-bright-muted',
        backgroundActive: 'secondary',
        backgroundDisabled: 'secondary',
        border: true,
        borderColor: 'notice-bright',
        borderHoverColor: 'notice-extra-bright',
        borderActiveColor: 'notice-dark',
        borderDisabledColor: 'notice-extra-bright',
        textColor: 'primary',
        disabledTextColor: 'primary',
        elevation: 'sm',
        glowHover: 'notice-inner',
      }
    default:
      return {}
  }
}

const getVariantProps = (
  variant: ButtonVariant,
  colorTheme: ButtonColorTheme
): VariantProps => {
  switch (variant) {
    case 'destructive':
      return getPrimaryVariantPropsByColor('negative')
    case 'primary':
      return getPrimaryVariantPropsByColor(colorTheme)
    case 'secondary':
      return {
        background: 'secondary',
        backgroundHover: 'secondary',
        backgroundActive: 'secondary',
        backgroundDisabled: 'secondary',
        border: true,
        borderColor: 'muted',
        borderHoverColor: 'secondary',
        borderActiveColor: 'secondary',
        borderDisabledColor: 'secondary',
        textColor: 'primary',
        disabledTextColor: 'primary',
        iconColor: 'info',
        elevation: 'sm',
      }
    case 'text':
      return {
        backgroundHover: 'text',
        backgroundActive: 'text',
        backgroundDisabled: 'text',
        border: true,
        borderColor: 'transparent',
        borderHoverColor: 'transparent',
        borderActiveColor: 'secondary',
        borderDisabledColor: 'secondary',
        textColor: 'secondary',
        activeTextColor: 'primary',
        hoverTextColor: 'primary',
        disabledTextColor: 'primary',
      }
    case 'radio':
      return getRadioVariantPropsByColor(colorTheme)
    default:
      return {}
  }
}

const getVariantClassName = (
  variant: ButtonVariant,
  textColor?: ThemeTextColor,
  borderColor?: ThemeBorderColor,
  colorTheme?: ButtonColorTheme
) => {
  const variantProps: VariantProps = {
    ...getVariantProps(variant, colorTheme ?? 'default'),
    ...(textColor && {
      textColor,
      hoverTextColor: undefined,
      activeTextColor: undefined,
      disabledTextColor: undefined,
    }),
    ...(borderColor && {
      borderColor,
      borderHoverColor: undefined,
      borderActiveColor: undefined,
      borderDisabledColor: undefined,
    }),
  }

  return `${getBackgroundClassNames(variantProps)} ${getBorderClassNames(variantProps)} ${getTextClassNames(variantProps)} ${getElevationClassName(variantProps)} ${getGlowHoverClassName(variantProps)}`
}

const getBackgroundClassNames = ({
  background,
  backgroundHover,
  backgroundActive,
  backgroundDisabled,
}: VariantProps) => {
  return `${getDefaultBackgroundClassName(background)} ${getHoverBackgroundClassName(backgroundHover)} ${getActiveBackgroundClassName(backgroundActive)} ${getDisabledBackgroundClassName(backgroundDisabled)}`
}

const getDefaultBackgroundClassName = (background?: ThemeBackground) => {
  if (!background) {
    return 'bg-transparent'
  }

  return themeBackgroundMap[background]
}

const getHoverBackgroundClassName = (
  backgroundHover?: ThemeHoverBackground
) => {
  if (!backgroundHover) {
    return 'hover:bg-transparent'
  }

  return themeHoverBackgroundMap[backgroundHover]
}

const getActiveBackgroundClassName = (
  backgroundActive?: ThemeActiveBackground
) => {
  if (!backgroundActive) {
    return 'active:bg-transparent'
  }

  return themeActiveBackgroundMap[backgroundActive]
}

const getDisabledBackgroundClassName = (
  backgroundDisabled?: ThemeDisabledBackground
) => {
  if (!backgroundDisabled) {
    return 'disabled:bg-transparent'
  }

  return themeDisabledBackgroundMap[backgroundDisabled]
}

const getBorderClassNames = ({
  border,
  borderColor,
  borderHoverColor,
  borderActiveColor,
  borderDisabledColor,
}: VariantProps) => {
  if (!border) {
    return ''
  }

  return `border border-solid ${getDefaultBorderClassName(borderColor)} ${getHoverBorderClassName(borderHoverColor)} ${getActiveBorderClassName(borderActiveColor)} ${getDisabledBorderClassName(borderDisabledColor)}`
}

const getDefaultBorderClassName = (borderColor?: ThemeBorderColor) => {
  if (!borderColor) {
    return 'border-transparent'
  }

  return themeBorderColorMap[borderColor]
}

const getHoverBorderClassName = (borderHoverColor?: ThemeHoverBorderColor) => {
  if (!borderHoverColor) {
    return 'hover:border-transparent'
  }

  return themeHoverBorderColorMap[borderHoverColor]
}

const getActiveBorderClassName = (
  borderActiveColor?: ThemeActiveBorderColor
) => {
  if (!borderActiveColor) {
    return 'active:border-transparent'
  }

  return themeActiveBorderColorMap[borderActiveColor]
}

const getDisabledBorderClassName = (
  borderDisabledColor?: ThemeDisabledBorderColor
) => {
  if (!borderDisabledColor) {
    return 'disabled:border-transparent'
  }

  return themeDisabledBorderColorMap[borderDisabledColor]
}

const getTextClassNames = (props: VariantProps) => {
  return `${getDefaultTextColorClassName(props)} ${getActiveTextColorClassName(props)} ${getHoverTextColorClassName(props)} ${getDisabledTextColorClassName(props)}`
}

const getDefaultTextColorClassName = ({ textColor }: VariantProps) => {
  if (textColor) {
    return themeTextColorMap[textColor]
  }
}

const getActiveTextColorClassName = ({ activeTextColor }: VariantProps) => {
  if (activeTextColor) {
    return themeActiveTextColorMap[activeTextColor]
  }
}

const getHoverTextColorClassName = ({ hoverTextColor }: VariantProps) => {
  if (hoverTextColor) {
    return themeHoverTextColorMap[hoverTextColor]
  }
}

const getDisabledTextColorClassName = ({ disabledTextColor }: VariantProps) => {
  if (!disabledTextColor) {
    return ''
  }

  return themeDisabledTextColorMap[disabledTextColor]
}

const getElevationClassName = ({ elevation }: VariantProps) => {
  if (!elevation) {
    return ''
  }

  return elevationMap[elevation]
}

const getGlowHoverClassName = ({ glowHover }: VariantProps) => {
  if (!glowHover) {
    return 'hover:bg-transparent'
  }

  return themeHoverGlowMap[glowHover]
}

const getButtonStyles = (
  size: ButtonSize,
  variant: ButtonVariant,
  textColor?: ThemeTextColor,
  borderColor?: ThemeBorderColor,
  circular?: boolean,
  colorTheme?: ButtonColorTheme
) =>
  cn(
    'flex items-center justify-center gap-2 rounded active:shadow-none disabled:shadow-none disabled:cursor-not-allowed active:scale-[0.98] transition-transform duration-100',
    { 'rounded-full': circular },
    getVariantClassName(variant, textColor, borderColor, colorTheme),
    getSizeClassName(size, circular)
  )

function useButtonStyles({
  variant,
  size,
  borderColor: borderColorProp,
  iconColor: iconColorProp,
  textColor: textColorProp,
  circular,
  colorTheme,
}: UseButtonStylesProps) {
  const buttonStyles = useMemo(
    () =>
      getButtonStyles(
        size,
        variant,
        textColorProp,
        borderColorProp,
        circular,
        colorTheme
      ),
    [circular, size, variant, textColorProp, borderColorProp, colorTheme]
  )
  const { iconColor, iconSize } = useMemo(() => {
    const { iconSize } = sizeClassMap[size]
    const { iconColor } =
      getVariantProps(variant, colorTheme ?? 'default') ?? {}
    return {
      iconSize,
      iconColor: iconColorProp ?? iconColor,
    }
  }, [variant, iconColorProp, size, colorTheme])

  return {
    buttonStyles,
    iconColor,
    iconSize,
  }
}

export {
  buttonColorThemeNames,
  buttonSizeNames,
  buttonVariantNames,
  getButtonStyles,
  type ButtonColorTheme,
  type ButtonSize,
  type ButtonVariant,
  type UseButtonStylesProps,
  useButtonStyles,
}
