import type { PropsWithChildren } from 'react'

import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import { type TestIds, getTestProps } from '../../../utils/testing.ts'

export const PageHeaderOverline = ({
  children,
  dataTestId: dataTestIdProp,
  dataTrackingId: dataTrackingIdProp,
}: PropsWithChildren<TestIds>) => {
  const {
    dataTestId: dataTestIdContext,
    dataTrackingId: dataTrackingIdContext,
  } = useTestTagsContext()
  const dataTestId = dataTestIdProp || `${dataTestIdContext}-overline`
  const dataTrackingId =
    dataTrackingIdProp || `${dataTrackingIdContext}-overline`

  return (
    <div
      className="flex flex-row items-center justify-start gap-4 h-10"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {children}
    </div>
  )
}
