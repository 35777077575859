import type { PropsWithChildren } from 'react'

import { getTextBaseClasses } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { TextProps } from '../text/text.tsx'
import { useFormFieldContext } from './use-form-field.ts'

type FormFieldLabelProps = PropsWithChildren<
  Readonly<Pick<TextProps, 'textColor' | 'fontWeight' | 'fontSize'>> & {
    className?: string
  }
>

const FormFieldLabel = ({
  children,
  className,
  fontSize = 'sm',
  fontWeight = 'medium',
  textColor = 'secondary',
}: FormFieldLabelProps) => {
  const context = useFormFieldContext()
  const labelProps = context?.getLabelProps() ?? {}

  return (
    <label
      {...labelProps}
      className={cn(
        'leading-normal',
        getTextBaseClasses({ fontSize, fontWeight, textColor }),
        className
      )}
    >
      {children}
    </label>
  )
}

export { FormFieldLabel }
