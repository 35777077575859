import { cn } from '@mntn-dev/ui-utilities'
import { type ButtonHTMLAttributes, forwardRef } from 'react'

import { themeHoverTextColorMap } from '@mntn-dev/ui-theme'

import { Icon, type IconProps } from '../icon/icon.tsx'
import type { IconHoverColor } from '../icon/types/core.ts'

type IconButtonProps = IconProps &
  Readonly<
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
      hoverColor?: IconHoverColor
      centered?: boolean
    }
  >

const IconButton = forwardRef<SVGSVGElement, IconButtonProps>(
  ({ hoverColor, centered, ...iconProps }, ref) => {
    return (
      <Icon
        {...iconProps}
        className={cn(
          iconProps.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          hoverColor && !iconProps.disabled
            ? `${themeHoverTextColorMap[hoverColor]} transition-colors`
            : null,
          centered ? 'self-center' : null
        )}
        ref={ref}
      />
    )
  }
)

export { IconButton, type IconButtonProps }
