import { z } from 'zod'

import {
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../review.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import {
  ProjectIdSchema,
  ProjectServiceIdSchema,
  ReviewIdSchema,
  RoundIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PreProductionReviewMakerProposalSubmittedReviewStatusSchema =
  ReviewStatusSchema.extract(['reviewing', 'resolving'])

export type PreProductionReviewMakerProposalSubmittedReviewStatus = z.infer<
  typeof PreProductionReviewMakerProposalSubmittedReviewStatusSchema
>

export const PreProductionReviewMakerProposalSubmittedRoundStatusSchema =
  RoundStatusSchema.extract(['in_progress'])

export type PreProductionReviewMakerProposalSubmittedRoundStatus = z.infer<
  typeof PreProductionReviewMakerProposalSubmittedRoundStatusSchema
>

export const PreProductionReviewMakerProposalSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'pre_production_review_maker_proposal_submitted',
    z
      .object({
        reviewId: ReviewIdSchema,
        projectId: ProjectIdSchema,
        projectServiceId: ProjectServiceIdSchema,
        reviewStatus:
          PreProductionReviewMakerProposalSubmittedReviewStatusSchema,
        roundId: RoundIdSchema,
        roundNumber: ReviewRoundNumberSchema,
        roundStatus: PreProductionReviewMakerProposalSubmittedRoundStatusSchema,
      })
      .merge(ActivityLinkTargets('project', 'service'))
  )
