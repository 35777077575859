'use client'

import { createContext } from '../../utils/context'
import { useState, useCallback } from 'react'

export type UseCollapsibleProps = {
  isOpen?: boolean
}

export type UseCollapsibleReturn = {
  isOpen: boolean
  toggleOpen: () => void
  setOpen: (state: boolean) => void
}

export function useCollapsible(
  props: UseCollapsibleProps
): UseCollapsibleReturn {
  const { isOpen: initialOpen = false } = props

  const [isOpen, setOpen] = useState(initialOpen)

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  return {
    isOpen,
    toggleOpen,
    setOpen,
  }
}

const [CollapsibleProvider, useCollapsibleContext] =
  createContext<UseCollapsibleReturn>({
    name: 'CollapsibleContext',
  })

export { CollapsibleProvider, useCollapsibleContext }
