'use client'

import {
  type LabelHTMLAttributes,
  useCallback,
  useId,
  useMemo,
  useState,
} from 'react'

import type { SetRequired } from 'type-fest'

import { createOptionalContext } from '../../utils/context.ts'
import { type TestIds, getTestProps } from '../../utils/testing.ts'

type UseFormFieldProps = TestIds & {
  disabled?: boolean
  id?: string
  readOnly?: boolean
  hasError?: boolean
  hasSuccess?: boolean
  hasWarning?: boolean
}

function useFormField(props: UseFormFieldProps) {
  const {
    dataTestId,
    dataTrackingId,
    disabled,
    id: idProp,
    readOnly,
    hasError,
    hasSuccess,
    hasWarning,
  } = props
  const [focused, setFocused] = useState(false)
  const [hasErrorText, setHasErrorText] = useState(false)
  const [hasHelpText, setHasHelpText] = useState(false)

  const uniqueId = useId()
  const id = useMemo(() => idProp ?? uniqueId, [idProp, uniqueId])
  const helpId = `${id}-help`
  const errorId = `${id}-error`
  const labelId = `${id}-label`

  const errorRef = useCallback(() => {
    setHasErrorText(true)
  }, [])

  const helpRef = useCallback(() => {
    setHasHelpText(true)
  }, [])

  const getErrorProps = useCallback(
    () => ({
      id: errorId,
      ref: errorRef,
      role: 'alert',
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-error` : undefined,
        dataTrackingId: dataTrackingId ? `${dataTrackingId}-error` : undefined,
      }),
    }),
    [dataTestId, dataTrackingId, errorId, errorRef]
  )

  const getSuccessProps = useCallback(
    () => ({
      id: errorId,
      ref: errorRef,
      role: 'alert',
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-success` : undefined,
        dataTrackingId: dataTrackingId
          ? `${dataTrackingId}-success`
          : undefined,
      }),
    }),
    [dataTestId, dataTrackingId, errorId, errorRef]
  )
  const getWarningProps = useCallback(
    () => ({
      id: errorId,
      ref: errorRef,
      role: 'alert',
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-warning` : undefined,
        dataTrackingId: dataTrackingId
          ? `${dataTrackingId}-warning`
          : undefined,
      }),
    }),
    [dataTestId, dataTrackingId, errorId, errorRef]
  )

  const getLabelProps = useCallback<
    () => SetRequired<LabelHTMLAttributes<HTMLLabelElement>, 'htmlFor'>
  >(
    () => ({
      id: labelId,
      htmlFor: id,
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-label` : undefined,
        dataTrackingId: dataTrackingId ? `${dataTrackingId}-label` : undefined,
      }),
    }),
    [dataTestId, dataTrackingId, id, labelId]
  )

  const getHelpProps = useCallback(
    () => ({
      id: helpId,
      ref: helpRef,
      ...getTestProps({
        dataTestId: dataTestId ? `${dataTestId}-help` : undefined,
        dataTrackingId: dataTrackingId ? `${dataTrackingId}-help` : undefined,
      }),
    }),
    [dataTestId, dataTrackingId, helpId, helpRef]
  )

  return {
    dataTestId,
    dataTrackingId,
    disabled,
    errorId,
    focused,
    helpId,
    labelId,
    getErrorProps,
    getHelpProps,
    getLabelProps,
    getSuccessProps,
    getWarningProps,
    hasErrorText,
    hasHelpText,
    id,
    hasError,
    hasSuccess,
    hasWarning,
    readOnly,
    setFocused,
  }
}

const [FormFieldProvider, useFormFieldContext] = createOptionalContext<
  ReturnType<typeof useFormField>
>({
  name: 'FormFieldContext',
})

export {
  FormFieldProvider,
  type UseFormFieldProps,
  useFormField,
  useFormFieldContext,
}
