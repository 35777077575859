import { themeBorderColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

const SidebarLayoutHeaderBar = ({ children }: PropsWithChildren) => (
  <div
    className={cn(
      'w-full h-18 px-8 py-4 border-b flex items-center justify-between',
      themeBorderColorMap.muted
    )}
  >
    {children}
  </div>
)

export { SidebarLayoutHeaderBar }
