import type { TupleToUnion } from 'type-fest'

export const backgroundImageKeys = ['videographer', 'working-women'] as const

export type BackgroundImage = TupleToUnion<typeof backgroundImageKeys>

export const backgroundImageMap: Record<BackgroundImage, string> = {
  videographer: 'bg-videographer',
  'working-women': 'bg-working-women',
}
