// Images Section
export const imageMimeTypes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/bmp': ['.bmp'],
  'image/webp': ['.webp'],
  'image/svg+xml': ['.svg'],
  'image/tiff': ['.tif', '.tiff'],
  'image/x-icon': ['.ico'],
} as const satisfies Record<string, string[]>

// Documents Section
export const documentMimeTypes = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'text/javascript': ['.js'],
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
  'application/json': ['.json'],
  'application/rtf': ['.rtf'],
  'application/xml': ['.xml'],
} as const satisfies Record<string, string[]>

// Image Document Section
export const imageDocumentMimeTypes = {
  'application/pdf': ['.pdf'],
} as const satisfies Record<string, string[]>

// Audio Section
export const audioMimeTypes = {
  'audio/mpeg': ['.mp3'],
  'audio/wav': ['.wav'],
  'audio/ogg': ['.ogg'],
  'audio/aac': ['.aac'],
  'audio/webm': ['.weba'],
  'audio/mp4': ['.m4a'],
  'audio/flac': ['.flac'],
} as const satisfies Record<string, string[]>

// Video Section
export const videoMimeTypes = {
  'video/mp4': ['.mp4'],
  'video/webm': ['.webm'],
  'video/ogg': ['.ogv'],
  'video/x-msvideo': ['.avi'],
  'video/mpeg': ['.mpeg', '.mpg'],
  'video/x-ms-wmv': ['.wmv'],
  'video/quicktime': ['.mov'],
} as const satisfies Record<string, string[]>

// Archives Section
export const archiveMimeTypes = {
  'application/zip': ['.zip'],
  'application/x-7z-compressed': ['.7z'],
  'application/x-rar-compressed': ['.rar'],
  'application/gzip': ['.gz'],
  'application/x-tar': ['.tar'],
  'application/x-bzip2': ['.bz2'],
} as const satisfies Record<string, string[]>

// Fonts Section
export const fontMimeTypes = {
  'font/woff': ['.woff'],
  'font/woff2': ['.woff2'],
} as const satisfies Record<string, string[]>

// Code and Markup Files Section
export const codeMimeTypes = {
  'text/html': ['.html', '.htm'],
  'text/css': ['.css'],
  'application/javascript': ['.js'],
  'application/typescript': ['.ts'],
  'application/x-sh': ['.sh'],
  'text/markdown': ['.md'],
} as const satisfies Record<string, string[]>

// Other common types Section
export const otherMimeTypes = {
  'application/octet-stream': ['.bin', '.exe', '.dll'],
  'application/x-www-form-urlencoded': ['.form'],
  'application/x-font-opentype': ['.otf'],
  'application/x-font-ttf': ['.ttf'],
} as const satisfies Record<string, string[]>
