import { CardDescription } from '../components/card-description.tsx'
import { CardHeading } from '../components/card-heading.tsx'
import { CardTitle } from '../components/card-title.tsx'

type SpotlightCardHeadingProps = {
  className?: string
  description?: string
  title: string
}

const SpotlightCardHeading = ({
  className,
  description,
  title,
}: SpotlightCardHeadingProps) => (
  <CardHeading className={className}>
    <CardTitle fontSize="3xl">{title}</CardTitle>
    {description && <CardDescription>{description}</CardDescription>}
  </CardHeading>
)

export { SpotlightCardHeading }
