import {
  type FontSize,
  type HeadingSize,
  type ThemeFlexJustifyContent,
  type ThemeTextColor,
  type ThemeWidthOption,
  fontSizeMap,
  getFlexJustifyContentClassName,
  widthMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { TestIds } from '../../utils/testing.ts'
import { Heading } from '../heading/index.ts'
import { CurrencyLabel } from './currency-label.tsx'

type CurrencyContainerProps = Readonly<
  {
    currency: string
    numeric?: {
      fontSize?: HeadingSize
      textColor?: ThemeTextColor
    }
    justifyContent?: ThemeFlexJustifyContent
    label?: {
      text: string
      textColor?: ThemeTextColor
      fontSize?: FontSize
    }
    symbol?: {
      fontSize?: FontSize
    }
    width?: ThemeWidthOption
  } & TestIds
>

export const CurrencyContainer = ({
  currency,
  dataTestId,
  dataTrackingId,
  justifyContent,
  label,
  numeric = {
    fontSize: '5xl',
  },
  symbol = {
    fontSize: '3xl',
  },
  width,
}: CurrencyContainerProps) => {
  const [symbolCharacter, ...remainingChars] = currency
  const currencyWithoutSymbol = remainingChars.join('')

  return (
    <div
      className={cn(
        'flex flex-row items-center',
        getFlexJustifyContentClassName(justifyContent ?? 'center'),
        width && widthMap[width]
      )}
    >
      {label && (
        <CurrencyLabel
          text={label.text}
          fontSize={label.fontSize}
          textColor={label.textColor}
          className="mr-2"
        />
      )}

      <span className="flex items-start gap-0.5">
        <span
          className={`text-primary-green drop-shadow-glow-green ${fontSizeMap[symbol.fontSize ?? '3xl']} font-bold leading-none`}
        >
          {symbolCharacter}
        </span>

        <Heading
          fontSize={numeric.fontSize ?? '5xl'}
          className="font-bold leading-none"
          textColor={numeric?.textColor}
          dataTestId={dataTestId}
          dataTrackingId={dataTrackingId}
        >
          {currencyWithoutSymbol}
        </Heading>
      </span>
    </div>
  )
}
