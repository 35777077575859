import {
  type FontSize,
  type ThemeTextColor,
  isFontSizeHeading,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { Heading } from '../heading/heading.tsx'
import { Text } from '../text/text.tsx'

export type BreadcrumbSimpleDividerProps = {
  className?: string
  divider?: string
  dividerSize?: FontSize
  dividerColor?: ThemeTextColor
}

export const BreadcrumbSimpleDivider = ({
  divider = '/',
  dividerSize = '2xl',
  dividerColor = 'info',
  className,
}: BreadcrumbSimpleDividerProps) => {
  if (isFontSizeHeading(dividerSize)) {
    return (
      <Heading
        fontSize={dividerSize}
        textColor={dividerColor}
        className={cn('inline', className)}
      >
        {divider}
      </Heading>
    )
  }

  return (
    <Text fontSize={dividerSize} textColor={dividerColor} className={className}>
      {divider}
    </Text>
  )
}
