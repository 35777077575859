'use client'

import type { PropsWithChildren } from 'react'

type ModalOverlineProps = Readonly<PropsWithChildren>

const ModalOverline = ({ children }: ModalOverlineProps) => {
  return (
    <div className="flex flex-col gap-2 self-start text-left w-full [:not(:has(.header-title))]:mb-8">
      {children}
    </div>
  )
}

export { ModalOverline, type ModalOverlineProps }
