import type ActivityKeys from './locales/en/activity.json'
import type ArchiveKeys from './locales/en/archive.json'
import type AuthDemoKeys from './locales/en/auth-demo.json'
import type BackgroundImageKeys from './locales/en/background-image.json'
import type ClientPageKeys from './locales/en/client-page.json'
import type DashboardKeys from './locales/en/dashboard.json'
import type DeliverableDetailsKeys from './locales/en/deliverable-details.json'
import type DeliverableListKeys from './locales/en/deliverable-list.json'
import type DeliverableKeys from './locales/en/deliverable.json'
import type EditServiceTypesKeys from './locales/en/edit-service.json'
import type ErrorKeys from './locales/en/error.json'
import type FeedKeys from './locales/en/feed.json'
import type FileManagerKeys from './locales/en/file-manager.json'
import type FileKeys from './locales/en/files.json'
import type GenericKeys from './locales/en/generic.json'
import type JobFunctionKeys from './locales/en/job-functions.json'
import type LoginKeys from './locales/en/login.json'
import type MakerCardKeys from './locales/en/maker-card.json'
import type MakerProfileKeys from './locales/en/maker-profile.json'
import type MatchesKeys from './locales/en/matches.json'
import type NavbarKey from './locales/en/navbar.json'
import type NotificationKeys from './locales/en/notification.json'
import type OfferDetailsKeys from './locales/en/offer-details.json'
import type OfferListKeys from './locales/en/offer-list.json'
import type OffersKeys from './locales/en/offers.json'
import type PackageCardKeys from './locales/en/package-card.json'
import type PackageCreateKeys from './locales/en/package-create.json'
import type PackageDeleteKeys from './locales/en/package-delete.json'
import type PackageDetailsKeys from './locales/en/package-details.json'
import type PackageListKeys from './locales/en/package-list.json'
import type PackageServiceDetailsKeys from './locales/en/package-service-details.json'
import type PackageServiceModalKeys from './locales/en/package-service-modal.json'
import type PackageTypesKeys from './locales/en/package-types.json'
import type PostProductionReviewKeys from './locales/en/post-production-review.json'
import type PricingKeys from './locales/en/pricing.json'
import type CompanyProfileFormKeys from './locales/en/profile-company-form.json'
import type ProfileFormKeys from './locales/en/profile-form.json'
import type ProfileKeys from './locales/en/profile.json'
import type ProjectDeliverablesKeys from './locales/en/project-deliverables.json'
import type ProjectDetailsKeys from './locales/en/project-details.json'
import type ProjectFooterKeys from './locales/en/project-footer.json'
import type ProjectFormKeys from './locales/en/project-form.json'
import type ProjectMatchingKeys from './locales/en/project-matching.json'
import type ProjectServicesKeys from './locales/en/project-services.json'
import type ProjectSetProductionModalKeys from './locales/en/project-set-production-modal.json'
import type ProjectSubmittedModalKeys from './locales/en/project-submitted-modal.json'
import type ProjectsNewKeys from './locales/en/projects-new.json'
import type ProjectsTableKeys from './locales/en/projects-table.json'
import type ProjectKeys from './locales/en/projects.json'
import type QuickviewKeys from './locales/en/quickview.json'
import type RegistrationKeys from './locales/en/registration.json'
import type SecondKeys from './locales/en/second.json'
import type ServiceCheckoutKeys from './locales/en/service-checkout.json'
import type ServiceCreateKeys from './locales/en/service-create.json'
import type ServiceDetailsKeys from './locales/en/service-details.json'
import type ServiceListKeys from './locales/en/service-list.json'
import type ServiceTypesKeys from './locales/en/service-types.json'
import type SurveyKeys from './locales/en/survey.json'
import type TabKeys from './locales/en/tab-names.json'
import type TagKeys from './locales/en/tags.json'
import type ToastKeys from './locales/en/toast.json'
import type TranslationKeys from './locales/en/translation.json'
import type UserKeys from './locales/en/users.json'
import type ValidationKeys from './locales/en/validation.json'
import type { defaultNS } from './settings.ts'

export * from './settings.ts'
export * from './types.ts'
export * from './utilities'
export * from './client.ts'
export * from './server.ts'

/**
 * This is the type definition for the i18next custom type options.
 * Import any new namespaces above and add them to the resources object below.
 */

declare module 'i18next' {
  export interface CustomTypeOptions {
    defaultNS: typeof defaultNS
    resources: {
      archive: typeof ArchiveKeys
      'auth-demo': typeof AuthDemoKeys
      'background-image': typeof BackgroundImageKeys
      'client-page': typeof ClientPageKeys
      'deliverable-details': typeof DeliverableDetailsKeys
      'deliverable-list': typeof DeliverableListKeys
      'edit-service': typeof EditServiceTypesKeys
      'file-manager': typeof FileManagerKeys
      'job-functions': typeof JobFunctionKeys
      'maker-profile': typeof MakerProfileKeys
      'maker-card': typeof MakerCardKeys
      notification: typeof NotificationKeys
      'offer-details': typeof OfferDetailsKeys
      'offer-list': typeof OfferListKeys
      'package-card': typeof PackageCardKeys
      'package-create': typeof PackageCreateKeys
      'package-delete': typeof PackageDeleteKeys
      'package-details': typeof PackageDetailsKeys
      'package-list': typeof PackageListKeys
      'package-service-details': typeof PackageServiceDetailsKeys
      'package-service-modal': typeof PackageServiceModalKeys
      'package-types': typeof PackageTypesKeys
      'post-production-review': typeof PostProductionReviewKeys
      'profile-company-form': typeof CompanyProfileFormKeys
      'profile-form': typeof ProfileFormKeys
      'project-deliverables': typeof ProjectDeliverablesKeys
      'project-details': typeof ProjectDetailsKeys
      'project-form': typeof ProjectFormKeys
      'project-footer': typeof ProjectFooterKeys
      'project-matching': typeof ProjectMatchingKeys
      'project-services': typeof ProjectServicesKeys
      'project-set-production-modal': typeof ProjectSetProductionModalKeys
      'project-submitted-modal': typeof ProjectSubmittedModalKeys
      'projects-new': typeof ProjectsNewKeys
      'projects-table': typeof ProjectsTableKeys
      'service-checkout': typeof ServiceCheckoutKeys
      'service-create': typeof ServiceCreateKeys
      'service-details': typeof ServiceDetailsKeys
      'service-list': typeof ServiceListKeys
      'service-types': typeof ServiceTypesKeys
      survey: typeof SurveyKeys
      'tab-names': typeof TabKeys
      activity: typeof ActivityKeys
      dashboard: typeof DashboardKeys
      deliverable: typeof DeliverableKeys
      error: typeof ErrorKeys
      feed: typeof FeedKeys
      files: typeof FileKeys
      generic: typeof GenericKeys
      login: typeof LoginKeys
      matches: typeof MatchesKeys
      navbar: typeof NavbarKey
      offers: typeof OffersKeys
      pricing: typeof PricingKeys
      profile: typeof ProfileKeys
      projects: typeof ProjectKeys
      quickview: typeof QuickviewKeys
      registration: typeof RegistrationKeys
      second: typeof SecondKeys
      tags: typeof TagKeys
      toast: typeof ToastKeys
      translation: typeof TranslationKeys
      users: typeof UserKeys
      validation: typeof ValidationKeys
    }
  }
}
