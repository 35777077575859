'use client'

import type { PropsWithChildren } from 'react'
import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import { getTestProps } from '../../../utils/testing.ts'

export const PageHeaderMain = ({ children }: PropsWithChildren) => {
  const { dataTestId, dataTrackingId } = useTestTagsContext()
  return (
    <div className="flex grow flex-col justify-start">
      <div
        {...getTestProps({
          dataTestId: `${dataTestId}-main`,
          dataTrackingId: `${dataTrackingId}-main`,
        })}
        className="flex flex-col items-start justify-start gap-1"
      >
        {children}
      </div>
    </div>
  )
}
