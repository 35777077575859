'use client'

import {
  type ThemeGapValue,
  themeBackgroundMap,
  themeDisabledRingColorMap,
  themeDisabledTextColorMap,
  themeFocusRingColorMap,
  themeHoverBorderColorMap,
  themeReadonlyRingColorMap,
  themeRingColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import {
  type MouseEvent,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Chip } from '../chip/chip.tsx'
import { Chips } from '../chip/chips.tsx'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'
import { Icon } from '../icon/icon.tsx'
import { Text } from '../text/text.tsx'
import { MultiselectPlusMore } from './multiselect-plus-more.tsx'
import {
  type MultiselectOptionItem,
  useMultiselectContext,
} from './use-multiselect.ts'
import { getShowMoreAttributes } from './utilities.ts'

type MultiselectVariant = 'popout' | 'inline'

type MultiselectChipsProps = UseFormFieldControlProps<HTMLDivElement> & {
  className?: string
  placeholder?: string
  singleLine?: boolean
  variant?: MultiselectVariant
}

const columnGapValue: ThemeGapValue = '2'

const MultiselectChips = forwardRef<HTMLDivElement, MultiselectChipsProps>(
  (
    {
      className,
      disabled: disabledProp,
      id,
      hasError: hasErrorProp,
      hasSuccess: hasSuccessProp,
      hasWarning: hasWarningProp,
      dataTestId,
      dataTrackingId,
      onBlur,
      readOnly: readOnlyProp,
      placeholder,
      singleLine,
      ...formFieldProps
    },
    ref
  ) => {
    const { isPopout, onRemove, selectedItems } = useMultiselectContext()
    const chipContainerRef = useRef<HTMLDivElement | null>(null)

    const [showMoreNumber, setShowMoreNumber] = useState<number>()
    const [showMoreOffset, setShowMoreOffset] = useState<number>()

    const { hasError, hasSuccess, hasWarning, disabled, readOnly } =
      useFormFieldControl<HTMLDivElement>({
        disabled: disabledProp,
        id,
        hasError: hasErrorProp,
        hasSuccess: hasSuccessProp,
        hasWarning: hasWarningProp,
        dataTestId,
        dataTrackingId,
        onBlur,
        readOnly: readOnlyProp,
      })

    const borderOverride =
      hasError || hasWarning || hasSuccess || disabled || readOnly

    const handleRemove =
      (item: MultiselectOptionItem) => (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        e.preventDefault()

        onRemove?.(item)
      }

    // biome-ignore lint/correctness/useExhaustiveDependencies: performance optimization - this layout effect should only be necessary to run if selected items changes.
    useLayoutEffect(() => {
      if (chipContainerRef.current && singleLine) {
        const [showMoreNumber, showMoreOffset] = getShowMoreAttributes(
          chipContainerRef.current,
          columnGapValue
        )

        setShowMoreNumber(showMoreNumber)
        setShowMoreOffset(showMoreOffset)
      }
    }, [selectedItems, singleLine])

    return (
      <div
        role={isPopout ? 'button' : undefined}
        ref={ref}
        className={cn(
          'w-full flex p-3 justify-between gap-2 items-center rounded ring-1 ring-inset focus:ring-inset transition-all duration-100 ease-in-out data-[disabled]:pointer-events-none data-[disabled]:cursor-default',
          themeBackgroundMap['container-secondary'],
          themeDisabledTextColorMap.primary,
          themeDisabledRingColorMap.muted,
          themeReadonlyRingColorMap.muted,
          {
            [`data-[open]:ring-primary-blue ${themeRingColorMap.muted} ${themeHoverBorderColorMap.muted}`]:
              !borderOverride,
            [cn(themeRingColorMap.positive, themeFocusRingColorMap.positive)]:
              hasSuccess,
            [cn(themeRingColorMap.negative, themeFocusRingColorMap.negative)]:
              hasError,
            [cn(themeRingColorMap.caution, themeFocusRingColorMap.caution)]:
              hasWarning,
          },
          className
        )}
        aria-disabled={disabled || readOnly}
        data-disabled={disabled || readOnly}
        {...formFieldProps}
      >
        {placeholder && (selectedItems ?? []).length === 0 && (
          <Text className="grow text-left" textColor="tertiary">
            {placeholder}
          </Text>
        )}
        <div className="grow overflow-hidden relative">
          {selectedItems && selectedItems.length > 0 && (
            <Chips
              ref={chipContainerRef}
              gap={columnGapValue}
              className={cn({
                'flex-wrap': !singleLine,
                'flex-nowrap': singleLine,
              })}
            >
              {selectedItems.map((selectedItem) => (
                <Chip
                  key={selectedItem.id}
                  id={selectedItem.id}
                  dismissable={!(disabled || readOnly)}
                  onDismissClick={handleRemove(selectedItem)}
                >
                  {selectedItem.name}
                </Chip>
              ))}
            </Chips>
          )}
          {showMoreNumber && (
            <MultiselectPlusMore
              count={showMoreNumber}
              offset={showMoreOffset}
            />
          )}
        </div>
        {isPopout && (
          <Icon
            name="ChevronDownIcon"
            size="sm"
            color="info"
            className="m-2 grow-0 shrink-0"
          />
        )}
      </div>
    )
  }
)

export { MultiselectChips, type MultiselectChipsProps }
