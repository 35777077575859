import type { TupleToUnion } from 'type-fest'
import { z } from 'zod'

import type {
  NonEmptyArray,
  SingleOrNonEmptyArray,
} from '@mntn-dev/utility-types'
import { OpaqueSchema, UniqueIdBuilder } from '../unique-id-builder.ts'
export { UuidSchema } from '../unique-id-builder.ts'

/**
 * AgreementId
 */

export const [
  AgreementIdTag,
  AgreementIdSchema,
  AgreementId,
  AgreementUrnSchema,
  AgreementUrn,
] = UniqueIdBuilder('agreement')
export type AgreementId = z.infer<typeof AgreementIdSchema>
export type AgreementUrn = z.infer<typeof AgreementUrnSchema>

/**
 * ActivityId
 */

export const [
  ActivityIdTag,
  ActivityIdSchema,
  ActivityId,
  ActivityUrnSchema,
  ActivityUrn,
] = UniqueIdBuilder('activity')
export type ActivityId = z.infer<typeof ActivityIdSchema>
export type ActivityUrn = z.infer<typeof ActivityUrnSchema>

/**
 * AgencyId
 */
export const [
  AgencyIdTag,
  AgencyIdSchema,
  AgencyId,
  AgencyUrnSchema,
  AgencyUrn,
] = UniqueIdBuilder('agency')
export type AgencyId = z.infer<typeof AgencyIdSchema>
export type AgencyUrn = z.infer<typeof AgencyUrnSchema>

/**
 * AgencyProfileId
 */
export const [
  AgencyProfileIdTag,
  AgencyProfileIdSchema,
  AgencyProfileId,
  AgencyProfileUrnSchema,
  AgencyProfileUrn,
] = UniqueIdBuilder('agency-profile')
export type AgencyProfileId = z.infer<typeof AgencyProfileIdSchema>
export type AgencyProfileUrn = z.infer<typeof AgencyProfileUrnSchema>

/**
 * BidId
 */
export const [BidIdTag, BidIdSchema, BidId, BidUrnSchema, BidUrn] =
  UniqueIdBuilder('bid')
export type BidId = z.infer<typeof BidIdSchema>
export type BidUrn = z.infer<typeof BidUrnSchema>

/**
 * BrandId
 */
export const [BrandIdTag, BrandIdSchema, BrandId, BrandUrnSchema, BrandUrn] =
  UniqueIdBuilder('brand')
export type BrandId = z.infer<typeof BrandIdSchema>
export type BrandUrn = z.infer<typeof BrandUrnSchema>

/**
 * CommentId
 */
export const [
  CommentIdTag,
  CommentIdSchema,
  CommentId,
  CommentUrnSchema,
  CommentUrn,
] = UniqueIdBuilder('comment')
export type CommentId = z.infer<typeof CommentIdSchema>
export type CommentUrn = z.infer<typeof CommentUrnSchema>

/**
 * DeliverableId
 */
export const [
  DeliverableIdTag,
  DeliverableIdSchema,
  DeliverableId,
  DeliverableUrnSchema,
  DeliverableUrn,
] = UniqueIdBuilder('deliverable')
export type DeliverableId = z.infer<typeof DeliverableIdSchema>
export type DeliverableUrn = z.infer<typeof DeliverableUrnSchema>

/**
 * FeedId
 */
export const [FeedIdTag, FeedIdSchema, FeedId, FeedUrnSchema, FeedUrn] =
  UniqueIdBuilder('feed')
export type FeedId = z.infer<typeof FeedIdSchema>
export type FeedUrn = z.infer<typeof FeedUrnSchema>

/**
 * FileId
 */
export const [FileIdTag, FileIdSchema, FileId, FileUrnSchema, FileUrn] =
  UniqueIdBuilder('file')
export type FileId = z.infer<typeof FileIdSchema>
export type FileUrn = z.infer<typeof FileUrnSchema>

/**
 * NotificationId
 */
export const [
  NotificationIdTag,
  NotificationIdSchema,
  NotificationId,
  NotificationUrnSchema,
  NotificationUrn,
] = UniqueIdBuilder('notification')
export type NotificationId = z.infer<typeof NotificationIdSchema>
export type NotificationUrn = z.infer<typeof NotificationUrnSchema>

/**
 * OfferId
 */
export const [OfferIdTag, OfferIdSchema, OfferId, OfferUrnSchema, OfferUrn] =
  UniqueIdBuilder('offer')
export type OfferId = z.infer<typeof OfferIdSchema>
export type OfferUrn = z.infer<typeof OfferUrnSchema>

/**
 * OrganizationId
 */
export const [
  OrganizationIdTag,
  OrganizationIdSchema,
  OrganizationId,
  OrganizationUrnSchema,
  OrganizationUrn,
] = UniqueIdBuilder('organization')
export type OrganizationId = z.infer<typeof OrganizationIdSchema>
export type OrganizationUrn = z.infer<typeof OrganizationUrnSchema>

/**
 * PackageId
 */
export const [
  PackageIdTag,
  PackageIdSchema,
  PackageId,
  PackageUrnSchema,
  PackageUrn,
] = UniqueIdBuilder('package')
export type PackageId = z.infer<typeof PackageIdSchema>
export type PackageUrn = z.infer<typeof PackageUrnSchema>

/**
 * PackageServiceId
 */
export const [
  PackageServiceIdTag,
  PackageServiceIdSchema,
  PackageServiceId,
  PackageServiceUrnSchema,
  PackageServiceUrn,
] = UniqueIdBuilder('package-service')
export type PackageServiceId = z.infer<typeof PackageServiceIdSchema>
export type PackageServiceUrn = z.infer<typeof PackageServiceUrnSchema>

/**
 * ProjectId
 */
export const [
  ProjectIdTag,
  ProjectIdSchema,
  ProjectId,
  ProjectUrnSchema,
  ProjectUrn,
] = UniqueIdBuilder('project')
export type ProjectId = z.infer<typeof ProjectIdSchema>
export type ProjectUrn = z.infer<typeof ProjectUrnSchema>

/**
 * ProjectServiceId
 */
export const [
  ProjectServiceIdTag,
  ProjectServiceIdSchema,
  ProjectServiceId,
  ProjectServiceUrnSchema,
  ProjectServiceUrn,
] = UniqueIdBuilder('project-service')
export type ProjectServiceId = z.infer<typeof ProjectServiceIdSchema>
export type ProjectServiceUrn = z.infer<typeof ProjectServiceUrnSchema>

/**
 * ProofId
 */
export const [ProofIdTag, ProofIdSchema, ProofId, ProofUrnSchema, ProofUrn] =
  UniqueIdBuilder('proof')
export type ProofId = z.infer<typeof ProofIdSchema>
export type ProofUrn = z.infer<typeof ProofUrnSchema>

/**
 * ReviewId
 */
export const [
  ReviewIdTag,
  ReviewIdSchema,
  ReviewId,
  ReviewUrnSchema,
  ReviewUrn,
] = UniqueIdBuilder('review')
export type ReviewId = z.infer<typeof ReviewIdSchema>
export type ReviewUrn = z.infer<typeof ReviewUrnSchema>

/**
 * RoundId
 */
export const [RoundIdTag, RoundIdSchema, RoundId, RoundUrnSchema, RoundUrn] =
  UniqueIdBuilder('round')
export type RoundId = z.infer<typeof RoundIdSchema>
export type RoundUrn = z.infer<typeof RoundUrnSchema>

/**
 * ServiceId
 */
export const [
  ServiceIdTag,
  ServiceIdSchema,
  ServiceId,
  ServiceUrnSchema,
  ServiceUrn,
] = UniqueIdBuilder('service')
export type ServiceId = z.infer<typeof ServiceIdSchema>
export type ServiceUrn = z.infer<typeof ServiceUrnSchema>

/**
 * SessionId
 */
export const [
  SessionIdTag,
  SessionIdSchema,
  SessionId,
  SessionUrnSchema,
  SessionUrn,
] = UniqueIdBuilder('session')
export type SessionId = z.infer<typeof SessionIdSchema>
export type SessionUrn = z.infer<typeof SessionUrnSchema>

/**
 * SurveyId
 */
export const [
  SurveyIdTag,
  SurveyIdSchema,
  SurveyId,
  SurveyUrnSchema,
  SurveyUrn,
] = UniqueIdBuilder('survey')
export type SurveyId = z.infer<typeof SurveyIdSchema>
export type SurveyUrn = z.infer<typeof SurveyUrnSchema>

/**
 * SurveyResponseId
 */
export const [
  SurveyResponseIdTag,
  SurveyResponseIdSchema,
  SurveyResponseId,
  SurveyResponseUrnSchema,
  SurveyResponseUrn,
] = UniqueIdBuilder('survey-response')
export type SurveyResponseId = z.infer<typeof SurveyResponseIdSchema>
export type SurveyResponseUrn = z.infer<typeof SurveyResponseUrnSchema>

/**
 * TagId
 */
export const [TagIdTag, TagIdSchema, TagId, TagUrnSchema, TagUrn] =
  UniqueIdBuilder('tag')
export type TagId = z.infer<typeof TagIdSchema>
export type TagUrn = z.infer<typeof TagUrnSchema>

/**
 * TeamId
 */
export const [TeamIdTag, TeamIdSchema, TeamIdOriginal, TeamUrnSchema, TeamUrn] =
  UniqueIdBuilder('team')
export type TeamId = z.infer<typeof TeamIdSchema>
export type TeamUrn = z.infer<typeof TeamUrnSchema>

// TODO: QF-3306 Remove these team <> agency/brand mappers
// Start ============================================================

/* c8 ignore start */

function fromAgencyId(agencyId: AgencyId): TeamId
function fromAgencyId(agencyId: AgencyId | undefined): TeamId | undefined
function fromAgencyId(agencyId: AgencyId | null): TeamId | null
function fromAgencyId(
  agencyId: AgencyId | undefined | null
): TeamId | undefined | null {
  return agencyId && TeamId(agencyId)
}

function toAgencyId(teamId: TeamId): AgencyId
function toAgencyId(teamId: TeamId | undefined): AgencyId | undefined
function toAgencyId(teamId: TeamId | null): AgencyId | null
function toAgencyId(
  teamId: TeamId | undefined | null
): AgencyId | undefined | null {
  return teamId && AgencyId(teamId)
}

function toAgencyIds(
  teamIds: SingleOrNonEmptyArray<TeamId> | undefined
): AgencyId | NonEmptyArray<AgencyId> | undefined {
  if (Array.isArray(teamIds)) {
    const [team, ...teams] = teamIds
    return [AgencyId(team), ...teams.map(AgencyId)]
  }
  return teamIds && AgencyId(teamIds)
}

function fromBrandId(brandId: BrandId): TeamId
function fromBrandId(brandId: BrandId | undefined): TeamId | undefined
function fromBrandId(brandId: BrandId | null): TeamId | null
function fromBrandId(
  brandId: BrandId | undefined | null
): TeamId | undefined | null {
  return brandId && TeamId(brandId)
}

function toBrandId(teamId: TeamId): BrandId
function toBrandId(teamId: TeamId | undefined): BrandId | undefined
function toBrandId(teamId: TeamId | null): BrandId | null
function toBrandId(
  teamId: TeamId | undefined | null
): BrandId | undefined | null {
  return teamId && BrandId(teamId)
}

function toBrandIds(
  teamIds: SingleOrNonEmptyArray<TeamId> | undefined
): BrandId | NonEmptyArray<BrandId> | undefined {
  if (Array.isArray(teamIds)) {
    const [team, ...teams] = teamIds
    return [BrandId(team), ...teams.map(BrandId)]
  }
  return teamIds && BrandId(teamIds)
}

export const TeamId = Object.assign(TeamIdOriginal, {
  fromAgencyId,
  toAgencyId,
  toAgencyIds,
  fromBrandId,
  toBrandId,
  toBrandIds,
})

/* c8 ignore end */

// End ============================================================

/**
 * ThreadId
 */
export const ThreadIdSchema = OpaqueSchema(
  z.number().int().positive(),
  'thread'
)
export type ThreadId = z.infer<typeof ThreadIdSchema>
export const ThreadId = (threadId: number) => ThreadIdSchema.parse(threadId)

/**
 * UserId
 */
export const [UserIdTag, UserIdSchema, UserId, UserUrnSchema, UserUrn] =
  UniqueIdBuilder('user')
export type UserId = z.infer<typeof UserIdSchema>
export type UserUrn = z.infer<typeof UserUrnSchema>

/**
 * WatchId
 */
export const [WatchIdTag, WatchIdSchema, WatchId, WatchUrnSchema, WatchUrn] =
  UniqueIdBuilder('watch')
export type WatchId = z.infer<typeof WatchIdSchema>
export type WatchUrn = z.infer<typeof WatchUrnSchema>

/**
 * SubjectId
 */
export const [SubjectIdTag, _subjectIdSchema, SubjectId] =
  UniqueIdBuilder('subject')
export const SubjectIdSchema = _subjectIdSchema
  .or(ProjectIdSchema)
  .or(FeedIdSchema)
export type SubjectId = z.infer<typeof SubjectIdSchema>

/**
 * TagListId
 */
export const TagListIdSchema = z.union([
  UserIdSchema,
  ProjectIdSchema,
  FileIdSchema,
])
export type TagListId = z.infer<typeof TagListIdSchema>

export const idTags = <const>[
  AgreementIdTag,
  ActivityIdTag,
  AgencyIdTag,
  BidIdTag,
  BrandIdTag,
  CommentIdTag,
  DeliverableIdTag,
  FeedIdTag,
  FileIdTag,
  NotificationIdTag,
  OfferIdTag,
  PackageIdTag,
  PackageServiceIdTag,
  ProjectIdTag,
  ProjectServiceIdTag,
  ProofIdTag,
  ReviewIdTag,
  RoundIdTag,
  ServiceIdTag,
  SessionIdTag,
  SurveyIdTag,
  SurveyResponseIdTag,
  TagIdTag,
  TeamIdTag,
  UserIdTag,
  WatchIdTag,
  SubjectIdTag,
]

export type IdTag = TupleToUnion<typeof idTags>
