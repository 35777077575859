import {
  type FormHTMLAttributes,
  type PropsWithChildren,
  type RefAttributes,
  forwardRef,
} from 'react'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { FormLayout } from './form-layout.tsx'
import { FormSectionSubtitle } from './form-section-subtitle.tsx'
import { FormSectionTitle } from './form-section-title.tsx'
import { FormSection } from './form-section.tsx'

type FormProps = PropsWithChildren<
  Readonly<
    TestIds &
      RefAttributes<HTMLFormElement> &
      FormHTMLAttributes<HTMLFormElement> & {
        gap?: string
      }
  >
>

const FormComponent = forwardRef<HTMLFormElement, FormProps>(
  (
    { gap = 'gap-8', children, dataTestId, dataTrackingId, ...formProps },
    ref
  ) => (
    <form
      ref={ref}
      {...formProps}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <div className={`flex h-full flex-col ${gap}`}>{children}</div>
    </form>
  )
)

const FormNamespace = Object.assign(FormComponent, {
  Layout: FormLayout,
  Section: FormSection,
  SectionTitle: FormSectionTitle,
  SectionSubtitle: FormSectionSubtitle,
})

export { FormNamespace as Form, type FormProps }
