import type { PropsWithChildren } from 'react'

import {
  type ThemeBackground,
  themeBackgroundMap,
  themeDivideColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'

type TableBodyProps = PropsWithChildren<{
  className?: string
  background?: ThemeBackground | null
}>

const TableBody = ({
  className = '',
  background = 'container-secondary',
  children,
}: TableBodyProps) => {
  return (
    <tbody
      className={cn(
        'divide-y',
        themeDivideColorMap.muted,
        background && themeBackgroundMap[background],
        className
      )}
    >
      {children}
    </tbody>
  )
}

export { TableBody, type TableBodyProps }
