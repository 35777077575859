import { z } from 'zod'

import {
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../review.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import {
  ProjectIdSchema,
  ProjectServiceIdSchema,
  ReviewIdSchema,
  RoundIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PreProductionReviewBrandFeedbackSubmittedReviewStatusSchema =
  ReviewStatusSchema.extract(['concepting', 'resolved'])

export type PreProductionReviewBrandFeedbackSubmittedReviewStatus = z.infer<
  typeof PreProductionReviewBrandFeedbackSubmittedReviewStatusSchema
>

export const PreProductionReviewBrandFeedbackSubmittedRoundStatusSchema =
  RoundStatusSchema.exclude(['in_progress'])

export type PreProductionReviewBrandFeedbackSubmittedRoundStatus = z.infer<
  typeof PreProductionReviewBrandFeedbackSubmittedRoundStatusSchema
>

export const PreProductionReviewBrandFeedbackSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'pre_production_review_brand_feedback_submitted',
    z
      .object({
        reviewId: ReviewIdSchema,
        projectId: ProjectIdSchema,
        projectServiceId: ProjectServiceIdSchema,
        reviewStatus:
          PreProductionReviewBrandFeedbackSubmittedReviewStatusSchema,
        roundId: RoundIdSchema,
        roundNumber: ReviewRoundNumberSchema,
        roundStatus: PreProductionReviewBrandFeedbackSubmittedRoundStatusSchema,
      })
      .merge(ActivityLinkTargets('project', 'service'))
  )
