import type { z } from 'zod'
import { ProjectDomainSelectModelSchema } from '../project.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectBiddingOpenedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_bidding_opened',
    ProjectDomainSelectModelSchema.pick({
      projectId: true,
      biddingCloseDate: true,
    })
      .required()
      .merge(ActivityLinkTargets('project'))
  )
export type ProjectBiddingOpenedActivityDetails = z.infer<
  typeof ProjectBiddingOpenedActivityDetailsSchema
>
