'use client'

import { useRef, useState } from 'react'
import type { AudioPlayerStatus } from './types.tsx'

export const useAudioPlayer = (src?: string) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const isDraggingRef = useRef(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [status, setStatus] = useState<AudioPlayerStatus>('unplayed')
  const isPlaying = status === 'playing'
  const isEnded = status === 'ended'

  const play = () => {
    if (audioRef.current) {
      if (isEnded && currentTime >= duration) {
        setCurrentTime(0)
      }

      audioRef.current.play()
      setStatus('playing')
    }
  }

  const pause = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      setStatus('paused')
    }
  }

  const handleAudioFrame = () =>
    isPlaying &&
    audioRef.current &&
    !isDraggingRef.current &&
    setCurrentTime((currentTime) =>
      // Safari sometimes jumps backwards so keep moving in a forward direction
      audioRef.current ? Math.max(currentTime, audioRef.current.currentTime) : 0
    )

  const handlePlayerControlClick = () => (isPlaying ? pause() : play())

  const handleEnded = () => {
    setStatus('ended')
    setCurrentTime(duration)
  }

  const handlePause = () => setStatus('paused')
  const handlePlay = () => setStatus('playing')

  const handleLoadedMetadata = () =>
    audioRef.current && setDuration(audioRef.current.duration)

  const handleError = () =>
    console.error(`Error playing audio file ${src}`, {
      error: audioRef.current?.error,
    })

  const handleSeek = (time: number) => {
    if (audioRef.current) {
      isDraggingRef.current = true
      audioRef.current.currentTime = time
      setCurrentTime(time)
    }
  }

  const handleSeekEnd = (time: number) => {
    isDraggingRef.current = false

    if (isEnded && time < duration) {
      play()
    }
  }

  const handleDragStart = () => {
    isDraggingRef.current = true
  }

  const handleDragEnd = () => {
    isDraggingRef.current = false
  }

  return {
    currentTime,
    duration,
    status,
    isPlaying,
    audioRef,
    handleAudioFrame,
    handlePlayerControlClick,
    handleEnded,
    handlePause,
    handlePlay,
    handleLoadedMetadata,
    handleError,
    handleSeek,
    handleSeekEnd,
    handleDragStart,
    handleDragEnd,
  }
}
