import { forwardRef, useMemo } from 'react'

import { themeIconColorMap, themeTextColorMap } from '@mntn-dev/ui-theme'

import { cn } from '@mntn-dev/ui-utilities'
import { getTestProps } from '../../utils'
import { IconComponentFactory } from './factory.tsx'
import { isHeroIconName } from './hero/types/names.ts'
import { isOutlineSvgIconName } from './svg/types/names.ts'
import { iconSizeClassMap, iconStrokeWidthClassMap } from './types/maps.ts'
import type { IconProps } from './types/props.ts'

const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      fill,
      name,
      color,
      size,
      strokeWidth,
      className,
      disabled,
      dataTestId,
      dataTrackingId,
      ...props
    },
    ref
  ) => {
    const IconComponent = useMemo(
      () => IconComponentFactory({ name, fill, size, color }),
      [name, fill, size, color]
    )

    const colorName = disabled ? 'disabled' : (color ?? 'primary')
    const colorClasses =
      isHeroIconName(name) || isOutlineSvgIconName(name)
        ? themeTextColorMap[colorName]
        : themeIconColorMap[colorName]

    return (
      <IconComponent
        {...props}
        className={cn(
          'shrink-0',
          strokeWidth !== undefined && iconStrokeWidthClassMap[strokeWidth],
          iconSizeClassMap[size],
          colorClasses,
          className
        )}
        {...getTestProps({ dataTestId, dataTrackingId })}
        ref={ref}
      />
    )
  }
)

export { Icon, type IconProps }
