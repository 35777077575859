import { cn } from '@mntn-dev/ui-utilities'
import {
  themeBackgroundMap,
  themeBorderColorMap,
  themeGlowMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import type { ButtonColorTheme } from '../buttons'

type RadioButtonStyleProps = {
  isChecked: boolean
  siblingIsChecked: boolean
  colorTheme?: ButtonColorTheme
}

const checkedStyleMap: Partial<Record<ButtonColorTheme, string | undefined>> = {
  positive: cn(
    themeBackgroundMap['positive-light'],
    themeBorderColorMap['positive-extra-bright'],
    themeGlowMap.positive
  ),
  notice: cn(
    themeBackgroundMap['notice-bright'],
    themeBorderColorMap['notice-extra-bright'],
    themeGlowMap.notice
  ),
  default: undefined,
}

const siblingCheckedStyleMap: Partial<
  Record<ButtonColorTheme, string | undefined>
> = {
  positive: cn(
    themeTextColorMap.secondary,
    themeBackgroundMap['container-tertiary'],
    themeBorderColorMap['positive-muted']
  ),
  notice: cn(
    themeTextColorMap.secondary,
    themeBackgroundMap['container-tertiary'],
    themeBorderColorMap['notice-muted']
  ),
  default: undefined,
}

export const getRadioButtonClassNames = ({
  isChecked,
  siblingIsChecked,
  colorTheme = 'default',
}: RadioButtonStyleProps): string => {
  const checkedClass = isChecked ? checkedStyleMap[colorTheme] : ''
  const siblingCheckedClass = siblingIsChecked
    ? siblingCheckedStyleMap[colorTheme]
    : ''

  return cn(checkedClass, siblingCheckedClass)
}
