import type { TupleToUnion } from 'type-fest'

import type {
  ThemeHeightOption,
  ThemeMaxHeightOption,
  ThemeMinHeightOption,
} from './sizing.ts'

const heightMap: Record<ThemeHeightOption, string> = {
  '0': 'h-0',
  px: 'h-px',
  '0.5': 'h-0.5',
  '1': 'h-1',
  '1.5': 'h-1.5',
  '2': 'h-2',
  '2.5': 'h-2.5',
  '3': 'h-3',
  '3.5': 'h-3.5',
  '4': 'h-4',
  '5': 'h-5',
  '6': 'h-6',
  '7': 'h-7',
  '8': 'h-8',
  '9': 'h-9',
  '10': 'h-10',
  '11': 'h-11',
  '12': 'h-12',
  '14': 'h-14',
  '16': 'h-16',
  '20': 'h-20',
  '24': 'h-24',
  '28': 'h-28',
  '32': 'h-32',
  '36': 'h-36',
  '40': 'h-40',
  '44': 'h-44',
  '48': 'h-48',
  '52': 'h-52',
  '56': 'h-56',
  '60': 'h-60',
  '64': 'h-64',
  '72': 'h-72',
  '80': 'h-80',
  '96': 'h-96',
  '112': 'h-112',
  '128': 'h-128',
  '144': 'h-144',
  '160': 'h-160',
  '176': 'h-176',
  '192': 'h-192',
  '222': 'h-222',
  '1/2': 'h-1/2',
  '1/3': 'h-1/3',
  '2/3': 'h-2/3',
  '1/4': 'h-1/4',
  '2/4': 'h-2/4',
  '3/4': 'h-3/4',
  '1/5': 'h-1/5',
  '2/5': 'h-2/5',
  '3/5': 'h-3/5',
  '4/5': 'h-4/5',
  '1/6': 'h-1/6',
  '2/6': 'h-2/6',
  '3/6': 'h-3/6',
  '4/6': 'h-4/6',
  '5/6': 'h-5/6',
  full: 'h-full',
  screen: 'h-screen',
  'screen-minus-10': 'h-screen-minus-10',
  'screen-minus-32': 'h-screen-minus-32',
  'screen-minus-64': 'h-screen-minus-64',
  svh: 'h-svh',
  lvh: 'h-lvh',
  dvh: 'h-dvh',
  min: 'h-min',
  max: 'h-max',
  fit: 'h-fit',
}

const minHeightMap: Record<ThemeMinHeightOption, string> = {
  '0': 'min-h-0',
  px: 'min-h-px',
  '0.5': 'min-h-0.5',
  '1': 'min-h-1',
  '1.5': 'min-h-1.5',
  '2': 'min-h-2',
  '2.5': 'min-h-2.5',
  '3': 'min-h-3',
  '3.5': 'min-h-3.5',
  '4': 'min-h-4',
  '5': 'min-h-5',
  '6': 'min-h-6',
  '7': 'min-h-7',
  '8': 'min-h-8',
  '9': 'min-h-9',
  '10': 'min-h-10',
  '11': 'min-h-11',
  '12': 'min-h-12',
  '14': 'min-h-14',
  '16': 'min-h-16',
  '20': 'min-h-20',
  '24': 'min-h-24',
  '28': 'min-h-28',
  '32': 'min-h-32',
  '36': 'min-h-36',
  '40': 'min-h-40',
  '44': 'min-h-44',
  '48': 'min-h-48',
  '52': 'min-h-52',
  '56': 'min-h-56',
  '60': 'min-h-60',
  '64': 'min-h-64',
  '72': 'min-h-72',
  '80': 'min-h-80',
  '96': 'min-h-96',
  '112': 'min-h-112',
  '128': 'min-h-128',
  '144': 'min-h-144',
  '160': 'min-h-160',
  '176': 'min-h-176',
  '192': 'min-h-192',
  '222': 'min-h-222',
  full: 'min-h-full',
  screen: 'min-h-screen',
  'screen-minus-10': 'min-h-screen-minus-10',
  'screen-minus-32': 'min-h-screen-minus-32',
  'screen-minus-64': 'h-screen-minus-64',
  svh: 'min-h-svh',
  lvh: 'min-h-lvh',
  dvh: 'min-h-dvh',
  min: 'min-h-min',
  max: 'min-h-max',
  fit: 'min-h-fit',
}

const maxHeightMap: Record<ThemeMaxHeightOption, string> = {
  '0': 'max-h-0',
  px: 'max-h-px',
  '0.5': 'max-h-0.5',
  '1': 'max-h-1',
  '1.5': 'max-h-1.5',
  '2': 'max-h-2',
  '2.5': 'max-h-2.5',
  '3': 'max-h-3',
  '3.5': 'max-h-3.5',
  '4': 'max-h-4',
  '5': 'max-h-5',
  '6': 'max-h-6',
  '7': 'max-h-7',
  '8': 'max-h-8',
  '9': 'max-h-9',
  '10': 'max-h-10',
  '11': 'max-h-11',
  '12': 'max-h-12',
  '14': 'max-h-14',
  '16': 'max-h-16',
  '20': 'max-h-20',
  '24': 'max-h-24',
  '28': 'max-h-28',
  '32': 'max-h-32',
  '36': 'max-h-36',
  '40': 'max-h-40',
  '44': 'max-h-44',
  '48': 'max-h-48',
  '52': 'max-h-52',
  '56': 'max-h-56',
  '60': 'max-h-60',
  '64': 'max-h-64',
  '72': 'max-h-72',
  '80': 'max-h-80',
  '96': 'max-h-96',
  '112': 'max-h-112',
  '128': 'max-h-128',
  '144': 'max-h-144',
  '160': 'max-h-160',
  '176': 'max-h-176',
  '192': 'max-h-192',
  '222': 'max-h-222',
  none: 'max-h-none',
  full: 'max-h-full',
  screen: 'max-h-screen',
  'screen-minus-10': 'max-h-screen-minus-10',
  'screen-minus-32': 'max-h-screen-minus-32',
  'screen-minus-64': 'h-screen-minus-64',
  svh: 'max-h-svh',
  lvh: 'max-h-lvh',
  dvh: 'max-h-dvh',
  min: 'max-h-min',
  max: 'max-h-max',
  fit: 'max-h-fit',
}

const heightNames = ['height', 'maxHeight', 'minHeight'] as const
type Height = TupleToUnion<typeof heightNames>

type ThemeHeight = {
  height?: ThemeHeightOption
  maxHeight?: ThemeMaxHeightOption
  minHeight?: ThemeMinHeightOption
}

export {
  heightMap,
  heightNames,
  minHeightMap,
  maxHeightMap,
  type Height,
  type ThemeHeight,
}
