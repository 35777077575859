import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { DeliverableReviewLevelSchema } from './deliverable.models.ts'
import {
  CostMarginPercentSchema,
  DescriptionSchema,
  DollarsSchema,
  NameSchema,
  NoteSchema,
} from './property.models.ts'
import { ReviewRoundCountSchema, ReviewStatusSchema } from './review.models.ts'
import {
  PreProductionReviewSchema,
  ServiceKeySchema,
  ServicePayoutSchema,
  ServiceTranslationsSchema,
  ServiceTypeSchema,
} from './service.models.ts'
import {
  PackageIdSchema,
  PackageServiceIdSchema,
  ProjectIdSchema,
  ProjectServiceIdSchema,
  ProjectServiceUrnSchema,
  ServiceIdSchema,
} from './unique-id.models.ts'

/**
 * Count
 */
export const ProjectServiceCountSchema = z.number().int().min(1)
export type ProjectServiceCount = z.infer<typeof ProjectServiceCountSchema>

/**
 * ProjectServiceDomainSelectModel
 */
export const ProjectServiceDomainSelectModelSchema = z.object({
  projectServiceId: ProjectServiceIdSchema,
  projectServiceUrn: ProjectServiceUrnSchema,
  projectId: ProjectIdSchema,
  serviceId: ServiceIdSchema,
  packageId: PackageIdSchema,
  packageServiceId: PackageServiceIdSchema,
  name: NameSchema,
  description: DescriptionSchema,
  serviceType: ServiceTypeSchema,
  serviceKey: ServiceKeySchema.optional(),
  translations: ServiceTranslationsSchema.optional(),
  cost: ServicePayoutSchema.optional(),
  costPlusMargin: DollarsSchema().optional(),
  costMarginPercent: CostMarginPercentSchema,
  brandNote: NoteSchema.optional(),
  internalPricingNote: NoteSchema.optional(),
  makerNote: NoteSchema.optional(),
  count: ProjectServiceCountSchema,
  preProductionReview: PreProductionReviewSchema,
  preProductionReviewRoundsUsed: ReviewRoundCountSchema.optional(),
  preProductionReviewStatus: ReviewStatusSchema.optional(),
  postProductionReview: DeliverableReviewLevelSchema,
})

export type ProjectServiceDomainSelectModel = z.infer<
  typeof ProjectServiceDomainSelectModelSchema
>

/**
 * ProjectServiceDomainInsertModel
 */
export const ProjectServiceDomainInsertModelSchema =
  ProjectServiceDomainSelectModelSchema.omit({
    projectServiceUrn: true,
    preProductionReviewStatus: true,
    preProductionReviewRoundsUsed: true,
    costPlusMargin: true,
  })
    .partial({
      projectServiceId: true,
    })
    .extend({
      costMarginPercent: CostMarginPercentSchema,
    })

export type ProjectServiceDomainInsertModel = z.infer<
  typeof ProjectServiceDomainInsertModelSchema
>

/**
 * ProjectServiceDomainUpdateModel
 */
export const ProjectServiceDomainUpdateModelSchema =
  ProjectServiceDomainSelectModelSchema.pick({
    preProductionReviewStatus: true,
    preProductionReviewRoundsUsed: true,
  })
    .merge(
      z.object({
        brandNote:
          ProjectServiceDomainSelectModelSchema.shape.brandNote.nullable(),
        internalPricingNote:
          ProjectServiceDomainSelectModelSchema.shape.internalPricingNote.nullable(),
        makerNote:
          ProjectServiceDomainSelectModelSchema.shape.makerNote.nullable(),
        cost: ProjectServiceDomainSelectModelSchema.shape.cost.nullable(),
      })
    )
    .partial()

export type ProjectServiceDomainUpdateModel = z.infer<
  typeof ProjectServiceDomainUpdateModelSchema
>

/**
 * ProjectServiceDomainSelectModelFilters
 */
export const ProjectServiceDomainSelectModelFiltersSchema = z
  .object({
    projectServiceId: ModelFilterSchema(ProjectServiceIdSchema),
    projectServiceUrn: ModelFilterSchema(ProjectServiceUrnSchema),
    projectId: ModelFilterSchema(ProjectIdSchema),
    serviceId: ModelFilterSchema(ServiceIdSchema),
    preProductionReview: ModelFilterSchema(PreProductionReviewSchema),
    postProductionReview: ModelFilterSchema(DeliverableReviewLevelSchema),
  })
  .partial()

export type ProjectServiceDomainSelectModelFilters = z.infer<
  typeof ProjectServiceDomainSelectModelFiltersSchema
>

export const [
  ProjectServiceCategories,
  ProjectServiceCategorySchema,
  ProjectServiceCategoryEnum,
] = EnumBuilder('service', 'deliverable')
export type ProjectServiceCategory = z.infer<
  typeof ProjectServiceCategorySchema
>
