import { cn } from '@mntn-dev/ui-utilities'
import { type FontFamily, fontFamilyMap } from './font-family.ts'
import { type FontSize, fontSizeMap } from './font-size.ts'
import { type FontWeight, fontWeightMap } from './font-weight.ts'
import { type ThemeTextColor, themeTextColorMap } from './text-color.ts'
import { type TextTransform, textTransformMap } from './text-transform.ts'

export type TextOptions = {
  fontSize?: FontSize
  fontWeight?: FontWeight
  textColor?: ThemeTextColor
  fontFamily?: FontFamily
  textTransform?: TextTransform
}

export const getTextBaseClasses = ({
  fontFamily,
  fontSize,
  fontWeight,
  textColor,
  textTransform,
}: TextOptions) => {
  return cn(
    fontFamily && fontFamilyMap[fontFamily],
    fontSize && fontSizeMap[fontSize],
    fontWeight && fontWeightMap[fontWeight],
    textColor && themeTextColorMap[textColor],
    textTransform && textTransformMap[textTransform]
  )
}
