'use client'

import type { HTMLAttributes, PropsWithChildren } from 'react'

import { cn } from '@mntn-dev/ui-utilities'
import { TestTagsProvider, useTestTags } from '../../../hooks/use-test-tags.ts'
import { type TestIds, getTestProps } from '../../../utils/testing.ts'
import { PageHeaderControls } from './page-header-controls.tsx'
import { PageHeaderMain } from './page-header-main.tsx'
import { PageHeaderOverlineBreadcrumbs } from './page-header-overline-breadcrumbs.tsx'
import { PageHeaderOverlineDivider } from './page-header-overline-divider.tsx'
import { PageHeaderOverlineLink } from './page-header-overline-link.tsx'
import { PageHeaderOverline } from './page-header-overline.tsx'
import { PageHeaderSubtitle } from './page-header-subtitle.tsx'
import { PageHeaderTitleBreadcrumbs } from './page-header-title-breadcrumbs.tsx'
import { PageHeaderTitle } from './page-header-title.tsx'

type PageHeaderProps = PropsWithChildren<
  TestIds & Readonly<HTMLAttributes<HTMLElement>>
>

const PageHeader = ({
  children,
  className,
  dataTestId,
  dataTrackingId,
}: PageHeaderProps) => {
  const context = useTestTags({ dataTestId, dataTrackingId })
  return (
    <TestTagsProvider value={context}>
      <div
        {...getTestProps({
          dataTestId,
          dataTrackingId,
        })}
        className={cn(
          'flex w-full shrink-0 basis-0 flex-row items-center gap-6 self-stretch justify-between has-[.header-title]:mb-8',
          className
        )}
      >
        {children}
      </div>
    </TestTagsProvider>
  )
}

const PageHeaderNamespace = Object.assign(PageHeader, {
  OverlineBreadcrumbs: PageHeaderOverlineBreadcrumbs,
  OverlineDivider: PageHeaderOverlineDivider,
  OverlineLink: PageHeaderOverlineLink,
  Subtitle: PageHeaderSubtitle,
  Title: PageHeaderTitle,
  TitleBreadcrumbs: PageHeaderTitleBreadcrumbs,
  Main: PageHeaderMain,
  Overline: PageHeaderOverline,
  Controls: PageHeaderControls,
})

export { PageHeaderNamespace as PageHeader, type PageHeaderProps }
