'use client'

import { RadioGroup as RadioGroupComponent } from '@headlessui/react'
import type { ReactNode } from 'react'

import { type TestIds, getTestProps } from '../../utils'
import { RadioBlock } from './radio-block'
import { RadioButton } from './radio-button'
import { RadioGroupProvider, useRadioGroup } from './use-radio-group'

export type RadioGroupProps = Readonly<
  TestIds & {
    children: Array<ReactNode>
    disabled?: boolean
    value?: string | null
    onChange: (value: string) => void
  }
>

const RadioGroup = ({
  disabled,
  value,
  children,
  dataTestId,
  dataTrackingId,
  onChange,
}: RadioGroupProps) => {
  const radioGroup = useRadioGroup({ value })

  return (
    <RadioGroupProvider value={radioGroup}>
      <RadioGroupComponent
        className="flex flex-row gap-4"
        disabled={disabled}
        value={value}
        onChange={onChange}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        {children}
      </RadioGroupComponent>
    </RadioGroupProvider>
  )
}

RadioGroup.Item = RadioBlock
RadioGroup.Button = RadioButton

export { RadioGroup }
