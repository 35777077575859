import type React from 'react'
import { toast } from 'react-hot-toast'

import {
  type ThemeBorderColor,
  themeBackgroundBlurMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'

import type { TestIds } from '../../utils/testing.ts'
import { IconButton } from '../buttons/icon-button.tsx'
import { Stack } from '../stack/stack.tsx'
import { Surface } from '../surface/surface.tsx'
import { Text } from '../text/text.tsx'

type ToastTheme = Extract<
  ThemeBorderColor,
  'positive' | 'negative' | 'caution' | 'info'
>

type ToastProps = TestIds & {
  message: { id: string }
  title: string
  body: string
  theme?: ToastTheme
  icon?: React.JSX.Element
}

const Toast: React.FC<ToastProps> = ({
  dataTestId,
  dataTrackingId,
  message,
  title,
  body,
  theme,
  icon,
}) => {
  return (
    <Surface
      border
      borderColor={theme ?? 'bright'}
      width="72"
      backgroundColor="container"
      className={cn('mr-4 select-none', themeBackgroundBlurMap['blur-lg'])}
      dataTestId={dataTestId}
      dataTrackingId={dataTrackingId}
    >
      <Stack gap="4" className="m-4">
        {icon && <div className="flex">{icon}</div>}
        <div className="flex flex-grow flex-col">
          <Text className="font-bold">{title}</Text>
          <Text className="mt-1" fontSize="sm" textColor="secondary">
            {body}
          </Text>
        </div>
        <div className="flex">
          <IconButton
            name="XMarkIcon"
            fill="solid"
            size="lg"
            onClick={() => toast.dismiss(message.id)}
          />
        </div>
      </Stack>
    </Surface>
  )
}

export { Toast, type ToastProps }
