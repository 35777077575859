import { themeBorderColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

const SpotlightCardMedia = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div
    className={cn(
      'flex-initial h-[500px] w-full overflow-hidden border rounded-lg',
      themeBorderColorMap.muted,
      className
    )}
  >
    {children}
  </div>
)

export { SpotlightCardMedia }
