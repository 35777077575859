'use client'

import React, { useEffect, useImperativeHandle, useRef } from 'react'

import { Textarea, type TextareaProps } from '../textarea/textarea.tsx'

type ModalTextareaProps = TextareaProps &
  Readonly<{
    autoFocus?: boolean
  }>

const ModalTextarea = React.forwardRef<HTMLTextAreaElement, ModalTextareaProps>(
  ({ autoFocus, ...props }, outerRef) => {
    const innerRef = useRef<HTMLTextAreaElement>(null)

    // biome-ignore lint/style/noNonNullAssertion: innerRef.current is guaranteed to be set by React lifecycle
    useImperativeHandle(outerRef, () => innerRef.current!, [])

    useEffect(() => {
      if (autoFocus) {
        const textarea = innerRef?.current
        if (textarea) {
          textarea.focus() // Set focus to the textarea
          const length = textarea.value.length ?? 0
          textarea.setSelectionRange(length, length) // And then move the cursor to the end
          textarea.scrollTop = textarea.scrollHeight // And scroll to the bottom
        }
      }
    }, [autoFocus])
    return <Textarea {...props} ref={innerRef} />
  }
)

export { ModalTextarea }
