import type { TupleToUnion } from 'type-fest'

export const textTransform = [
  'uppercase',
  'lowercase',
  'capitalize',
  'normal',
] as const
export type TextTransform = TupleToUnion<typeof textTransform>

export const textTransformMap: Record<TextTransform, string> = {
  capitalize: 'capitalize',
  lowercase: 'lowercase',
  uppercase: 'uppercase',
  normal: 'normal-case',
}
