'use client'

import type { CSSProperties } from 'react'

type Props = Readonly<{
  style?: CSSProperties
}>

export const Progress = ({ style }: Props) => {
  return (
    <div
      className="absolute left-0 top-0 h-full bg-primary-blue rounded-full"
      style={style}
    />
  )
}
