'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { Text } from '../text/text.tsx'

type Props = { time: number; right?: boolean }

const formatTimestamp = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

export const Timestamp = ({ time, right }: Props) => (
  <Text
    className={cn('flex-shrink-0 min-w-[46px]', { 'text-right': right })}
    fontSize="med"
    fontWeight="medium"
    textColor="secondary"
  >
    {formatTimestamp(time)}
  </Text>
)
