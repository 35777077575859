import type { JSX } from 'react'

import { cn } from '@mntn-dev/ui-utilities'

import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { type UseSizingProps, useSizing } from '../../hooks/index.ts'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { Heading } from '../heading'

type BladeEnhancementInfoProps = Readonly<
  {
    banner?: JSX.Element
    title: string
    description: string
    content?: JSX.Element
    cost?: string
    priceText?: string
    feature?: JSX.Element
    actions?: JSX.Element
    onClick?: () => void
  } & UseSizingProps &
    TestIds
>

export const BladeEnhancementInfo = ({
  banner,
  dataTestId,
  dataTrackingId,
  title,
  description,
  content,
  cost,
  priceText,
  feature,
  actions,
  onClick,
  ...props
}: BladeEnhancementInfoProps) => {
  const sizing = useSizing({
    ...props,
  })

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      onClick={onClick}
      className={cn(
        'relative flex w-full grow flex-col justify-between px-6 py-6',
        onClick ? 'cursor-pointer' : '',
        ...Object.values(sizing)
      )}
    >
      {feature && <span className="absolute right-4 top-4">{feature}</span>}
      <span className="flex flex-col gap-2">
        <span>{banner}</span>
        <span className={cn(feature && 'pr-4')}>
          <Heading
            fontSize="xl"
            dataTestId={`${dataTestId}-title`}
            dataTrackingId={`${dataTrackingId}-title`}
          >
            {title}
          </Heading>
        </span>
        <span
          className={`line-clamp-3 self-stretch text-sm font-medium ${themeTextColorMap.tertiary}`}
        >
          {description}
        </span>
        {content}
      </span>
      {(priceText || cost || actions) && (
        <span className="flex justify-between pt-2">
          <span className="flex flex-col items-start justify-center gap-1">
            {priceText && (
              <span
                className={`text-sm font-semibold uppercase leading-3 ${themeTextColorMap.tertiary}`}
                {...getTestProps({
                  dataTestId: `${dataTestId}-price`,
                  dataTrackingId: `${dataTrackingId}-price`,
                })}
              >
                {priceText}
              </span>
            )}
            {cost && (
              <span
                className="flex items-center justify-start leading-6"
                {...getTestProps({
                  dataTestId: `${dataTestId}-cost`,
                  dataTrackingId: `${dataTrackingId}-cost`,
                })}
              >
                <Heading fontSize="2xl">{cost}</Heading>
              </span>
            )}
          </span>
          {actions && <span className="flex items-end">{actions}</span>}
        </span>
      )}
    </div>
  )
}
