import type { KeyPrefix } from 'i18next'
import type { useTranslation } from 'react-i18next'

import type { LanguageId } from '@mntn-dev/domain-types'
import type { TFunction } from 'i18next'
import type { FallbackNs } from 'react-i18next'

export type UseTranslationArgs = Parameters<typeof useTranslation>
export type TranslationNamespace = Exclude<UseTranslationArgs[0], undefined>
export type TranslationOptions = UseTranslationArgs[1]

export type LanguageConfig = {
  lng: LanguageId
}

export type WithLanguageParam<T = unknown> = T & {
  params?: { lng?: LanguageId }
}

export type HasLanguageParam<T = unknown> = T & {
  params: { lng: LanguageId }
}

/**
 * Narrow down a multi-namespace TFunction to a subset TFunction.
 * E.g., If you have t: TFunction<["generic", "users", "validation"]> on hand, and need to pass it into a function
 *       expecting t: TFunction<"validation"]>, you can pass it as t: NarrowTFunction<['validation']>(t),
 */
export function NarrowTFunction<
  Ns extends TranslationNamespace,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(
  t: ReturnType<typeof useTranslation>['t']
): TFunction<FallbackNs<Ns>, KPrefix> {
  return t as TFunction<FallbackNs<Ns>, KPrefix>
}
