'use client'

import { useResizeObserver } from '@mntn-dev/ui-utilities'
import { type RefObject, useEffect, useRef, useState } from 'react'
import { Progress } from './progress.tsx'
import { Scrubber } from './scrubber.tsx'
import { Timestamp } from './timestamp.tsx'

type Props = Readonly<{
  currentTime: number
  duration: number
  onDragStart: () => void
  onDragEnd: () => void
  onSeek: (time: number) => void
  onSeekEnd: (time: number) => void
}>

export const Timeline = ({
  currentTime,
  duration,
  onDragStart,
  onDragEnd,
  onSeek,
  onSeekEnd,
}: Props) => {
  const barRef = useRef<HTMLDivElement>(null)
  const scrubberRef = useRef<HTMLDivElement>(null)

  const { width: barWidth } = useResizeObserver({
    ref: barRef as RefObject<HTMLElement>,
  })

  const [scrubberWidth, setScrubberWidth] = useState(0)

  useEffect(() => {
    scrubberRef.current && setScrubberWidth(scrubberRef.current.offsetWidth)
  }, [])

  // Keep the scrubber from going past the bar to match up with the native input range behavior
  const scaleMinusScrubber = barWidth
    ? (barWidth - scrubberWidth) / barWidth
    : 0

  const ratio = duration ? (currentTime / duration) * scaleMinusScrubber : 0
  const percent = `${(ratio * 100).toFixed(2)}%`

  return (
    <div className="flex items-center gap-2 w-full">
      <Timestamp time={currentTime} />

      <div
        ref={barRef}
        className="relative flex-grow h-0.5 bg-white/55 rounded-full w-full"
      >
        <Progress style={{ width: percent }} />

        <Scrubber
          scrubberRef={scrubberRef}
          style={{ left: percent }}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />

        <input
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          step="any"
          onChange={(e) => onSeek(Number.parseFloat(e.currentTarget.value))}
          onMouseUp={(e) => onSeekEnd(Number.parseFloat(e.currentTarget.value))}
          onTouchEnd={(e) =>
            onSeekEnd(Number.parseFloat(e.currentTarget.value))
          }
          className="absolute w-full h-full opacity-0 cursor-pointer top-0 left-0"
        />
      </div>

      <Timestamp time={duration} right />
    </div>
  )
}
