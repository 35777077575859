'use client'

import { useAnimationFrame } from 'framer-motion'

type FrameCallback = Parameters<typeof useAnimationFrame>[0]

export const AnimationFrame = ({ onFrame }: { onFrame: FrameCallback }) => {
  useAnimationFrame(onFrame)
  return null
}
