'use client'

import type { CSSProperties, Ref } from 'react'

type Props = Readonly<{
  scrubberRef: Ref<HTMLDivElement>
  style?: CSSProperties
  onDragStart: () => void
  onDragEnd: () => void
}>

export const Scrubber = ({
  scrubberRef,
  style,
  onDragStart,
  onDragEnd,
}: Props) => {
  return (
    <div
      ref={scrubberRef}
      className="absolute top-1/2 -translate-y-1/2 h-[15px] w-[15px] bg-primary-blue border-[3px] border-white rounded-full cursor-pointer"
      style={style}
      onMouseDown={onDragStart}
      onMouseUp={onDragEnd}
      onTouchStart={onDragStart}
      onTouchEnd={onDragEnd}
    />
  )
}
