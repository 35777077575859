'use client'

import { createContext } from '../../utils/context'

export type UseRadioGroupProps = {
  value?: string | null
}

export function useRadioGroup(props: UseRadioGroupProps) {
  const { value } = props

  const getRadioGroupProps = () => ({
    value,
  })

  return { getRadioGroupProps, value }
}

export type UseRadioGroupReturn = ReturnType<typeof useRadioGroup>

const [RadioGroupProvider, useRadioGroupContext] =
  createContext<UseRadioGroupReturn>({
    name: 'RadioGroupContext',
  })

export { RadioGroupProvider, useRadioGroupContext }
