import { EnumBuilder } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { NameSchema } from './property.models.ts'
import {
  OrganizationId,
  OrganizationIdSchema,
  OrganizationUrn,
  OrganizationUrnSchema,
} from './unique-id.models.ts'

export const InternalOrganizationId = OrganizationId.Empty
export const InternalOrganizationUrn = OrganizationUrn(InternalOrganizationId)

/**
 * OrganizationType
 */
export const ExternalOrganizationTypes = <const>['brand', 'agency']
export const InternalOrganizationTypes = <const>['internal']

export const [OrganizationTypes, OrganizationTypeSchema, OrganizationTypeEnum] =
  EnumBuilder(...ExternalOrganizationTypes, ...InternalOrganizationTypes)
export type OrganizationType = z.infer<typeof OrganizationTypeSchema>

export const [, ExternalOrganizationTypeSchema, ExternalOrganizationTypeEnum] =
  EnumBuilder(...ExternalOrganizationTypes)
export type ExternalOrganizationType = z.infer<
  typeof ExternalOrganizationTypeSchema
>

export function isOrganizationType(value: string): value is OrganizationType {
  return OrganizationTypeSchema.safeParse(value).success
}

export const OrganizationDomainSelectModelSchema = z.object({
  organizationId: OrganizationIdSchema,
  organizationUrn: OrganizationUrnSchema,
  organizationType: OrganizationTypeSchema,
  name: NameSchema,
})

export type OrganizationDomainSelectModel = z.infer<
  typeof OrganizationDomainSelectModelSchema
>

export const OrganizationDomainInsertModelSchema =
  OrganizationDomainSelectModelSchema.pick({
    organizationId: true,
    organizationType: true,
    name: true,
  }).partial({
    organizationId: true,
  })

export type OrganizationDomainInsertModel = z.infer<
  typeof OrganizationDomainInsertModelSchema
>

export const OrganizationDomainUpdateModelSchema =
  OrganizationDomainSelectModelSchema.pick({
    name: true,
  }).partial()

export type OrganizationDomainUpdateModel = z.infer<
  typeof OrganizationDomainUpdateModelSchema
>

export const OrganizationDomainSelectModelFiltersSchema = z
  .object({
    organizationId: ModelFilterSchema(OrganizationIdSchema),
    // When filtering, transform names to lower case so the
    // "lower(organizations.name)" index can be used.
    name: ModelFilterSchema(NameSchema.toLowerCase()),
    organizationType: ModelFilterSchema(OrganizationTypeSchema),
  })
  .partial()

export type OrganizationDomainSelectModelFilters = z.infer<
  typeof OrganizationDomainSelectModelFiltersSchema
>
