'use client'
import type { TestIds } from '../../utils/testing.ts'
import { Text } from '../text/text'
import { CollapsibleButton } from './collapsible-button.tsx'

type CollapsibleTitleProps = {
  title: string
} & TestIds

const CollapsibleTitle = ({ title }: CollapsibleTitleProps) => {
  return (
    <div className="pl-8 pr-2 py-2">
      <CollapsibleButton>
        <Text
          textColor="secondary"
          fontSize="xs"
          textTransform="uppercase"
          fontWeight="semibold"
          className="flex items-center"
        >
          {title}
        </Text>
      </CollapsibleButton>
    </div>
  )
}

export { CollapsibleTitle }
