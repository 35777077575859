import { z } from 'zod'

import type { NonEmptyArray } from '@mntn-dev/utility-types'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { TimestampSchema } from './property.models.ts'
import {
  OfferIdSchema,
  OfferUrnSchema,
  ProjectIdSchema,
  TeamIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * OfferStatus
 */
export const [OfferStatuses, OfferStatusSchema, OfferStatusEnum] = EnumBuilder(
  'accepted',
  'archived',
  'declined',
  'draft',
  'expired',
  'matched',
  'pending'
)
export type OfferStatus = z.infer<typeof OfferStatusSchema>

export const ActiveOfferStatuses: NonEmptyArray<OfferStatus> = [
  'pending',
  'accepted',
  'matched',
]

export const MakerViewableOfferStatuses: NonEmptyArray<OfferStatus> = [
  'accepted',
  'archived',
  'declined',
  'expired',
  'matched',
  'pending',
]

/**
 * OfferDomainSelectModel
 */
export const OfferDomainSelectModelSchema = z.object({
  offerId: OfferIdSchema,
  offerUrn: OfferUrnSchema,
  projectId: ProjectIdSchema,
  acceptorId: UserIdSchema,
  agencyTeamId: TeamIdSchema,
  status: OfferStatusSchema,
  statusTimestamp: TimestampSchema,
  offerSentTimestamp: TimestampSchema.optional(),
})

export type OfferDomainSelectModel = z.infer<
  typeof OfferDomainSelectModelSchema
>

/**
 * OfferDomainInsertModel
 */
export const OfferDomainInsertModelSchema = OfferDomainSelectModelSchema.omit({
  offerSentTimestamp: true,
  offerUrn: true,
  statusTimestamp: true,
}).partial({ offerId: true })

export type OfferDomainInsertModel = z.infer<
  typeof OfferDomainInsertModelSchema
>

/**
 * OfferDomainUpdateModel
 */
export const OfferDomainUpdateModelSchema = OfferDomainSelectModelSchema.omit({
  offerId: true,
  offerUrn: true,
  statusTimestamp: true,
}).partial()

export type OfferDomainUpdateModel = z.infer<
  typeof OfferDomainUpdateModelSchema
>

/**
 * OfferDomainSelectModelFilter
 */
export const OfferDomainSelectModelFiltersSchema = z
  .object({
    offerId: ModelFilterSchema(OfferIdSchema),
    offerUrn: ModelFilterSchema(OfferUrnSchema),
    projectId: ModelFilterSchema(ProjectIdSchema),
    acceptorId: ModelFilterSchema(UserIdSchema),
    agencyTeamIds: ModelFilterSchema(TeamIdSchema),
    status: ModelFilterSchema(OfferStatusSchema),
  })
  .partial()

export type OfferDomainSelectModelFilters = z.infer<
  typeof OfferDomainSelectModelFiltersSchema
>
