import { z } from 'zod'

type Unit =
  | 'Years'
  | 'Year'
  | 'Yrs'
  | 'Yr'
  | 'Y'
  | 'Weeks'
  | 'Week'
  | 'W'
  | 'Days'
  | 'Day'
  | 'D'
  | 'Hours'
  | 'Hour'
  | 'Hrs'
  | 'Hr'
  | 'H'
  | 'Minutes'
  | 'Minute'
  | 'Mins'
  | 'Min'
  | 'M'
  | 'Seconds'
  | 'Second'
  | 'Secs'
  | 'Sec'
  | 's'
  | 'Milliseconds'
  | 'Millisecond'
  | 'Msecs'
  | 'Msec'
  | 'Ms'

type UnitAnyCase = Unit | Uppercase<Unit> | Lowercase<Unit>

export type TimeString =
  | `${number}`
  | `${number}${UnitAnyCase}`
  | `${number} ${UnitAnyCase}`

const unitRegex =
  /Years?|Yrs?|Weeks?|Days?|Hours?|Hrs?|Minutes?|Mins?|Seconds?|Secs?|Milliseconds?|Msecs?|Y|W|D|H|M|s|Ms/i
const timeStringRegex = new RegExp(`^\\d+(\\s*(?:${unitRegex.source}))?$`, 'i')

export const TimeStringSchema = z.string().regex(timeStringRegex, {
  message: 'Invalid TimeString format',
})

export const isTimeString = (value: unknown): value is TimeString => {
  return TimeStringSchema.safeParse(value).success
}
