import type { UseSizingProps } from '../../hooks/use-sizing.ts'
import type { TestIds } from '../../utils/testing.ts'
import type { IconName } from '../icon/index.ts'

export type AudioPlayerProps = Readonly<
  TestIds &
    UseSizingProps & {
      src: string
      name: string
    }
>

export type AudioPlayerStatus = 'unplayed' | 'paused' | 'playing' | 'ended'

export const audioIconByStatusMap: Record<AudioPlayerStatus, IconName> = {
  unplayed: 'PlayCircleIcon',
  playing: 'PauseCircleIcon',
  paused: 'PlayCircleIcon',
  ended: 'ArrowPathCircleIcon',
}
