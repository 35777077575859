'use client'
import { themeBackgroundBlurMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import {
  type Table as ReactTableType,
  type Row,
  flexRender,
} from '@tanstack/react-table'
import type { Virtualizer } from '@tanstack/react-virtual'
import type { ComponentType } from 'react'
import {
  type UseVirtualizerProps,
  useVirtualizer,
} from '../../hooks/use-virtualizer.tsx'
import { Surface } from '../surface/surface.tsx'
import { Table } from '../table/table'
import type { DataTableRowProps } from './data-table-row.tsx'

type Props<T> = {
  className?: string
  table: ReactTableType<T>
  fullHeight?: boolean
  fullWidth?: boolean
  tableId?: string
  rowIdentifier?: string
  scroll?: boolean
  loading?: boolean
  onClick?: (data: T) => void
  rowComponent: ComponentType<DataTableRowProps<T>>
  virtualizationOptions?: Omit<
    UseVirtualizerProps<HTMLDivElement, Element>,
    'count'
  >
}

function VirtualRows<T>({
  onClick,
  rowComponent: RowComponent,
  rowIdentifier = '',
  rows,
  tableId,
  virtualizer,
}: {
  onClick: (data: T) => () => void
  rows: Row<T>[]
  virtualizer: Virtualizer<HTMLDivElement, Element>
} & Pick<Props<T>, 'rowComponent' | 'tableId' | 'rowIdentifier' | 'onClick'>) {
  return virtualizer.getVirtualItems().map((virtualRow, index) => {
    const row = rows[virtualRow.index]

    if (!row) {
      return null
    }
    return (
      <RowComponent
        key={row.id}
        row={row}
        data={row.original}
        tableId={tableId}
        rowIdentifier={rowIdentifier}
        onClick={onClick(row.original)}
        style={{
          height: `${virtualRow.size}px`,
          transform: `translateY(${
            virtualRow.start - index * virtualRow.size
          }px)`,
        }}
      />
    )
  })
}

function DefaultRows<T>({
  onClick,
  rowComponent: RowComponent,
  rowIdentifier = '',
  rows,
  tableId,
}: { rows: Row<T>[]; onClick: (data: T) => () => void } & Pick<
  Props<T>,
  'rowComponent' | 'rowIdentifier' | 'tableId' | 'onClick'
>) {
  return rows.map((row) => (
    <RowComponent
      key={row.id}
      row={row}
      data={row.original}
      tableId={tableId}
      rowIdentifier={rowIdentifier}
      onClick={onClick(row.original)}
    />
  ))
}

export const DataTable = <T,>({
  className = '',
  table,
  fullHeight = false,
  fullWidth = false,
  tableId,
  rowIdentifier = '',
  scroll = false,
  onClick,
  rowComponent,
  virtualizationOptions: virtualizationOptionsProp,
}: Props<T>) => {
  const handleRowClick = (data: T) => () => {
    if (onClick) {
      onClick(data)
    }
  }

  const virtualizationOptions = virtualizationOptionsProp ?? {
    estimateSize: () => 72,
    getScrollElement: () => null,
    enabled: false,
  }

  const [virtualizer, VirtualizationProvider] = useVirtualizer({
    count: table.getRowCount(),
    ...virtualizationOptions,
  })

  const { rows } = table.getRowModel()

  return (
    <VirtualizationProvider value={virtualizer}>
      <Surface
        className={className}
        height={fullHeight ? 'full' : 'fit'}
        width={fullWidth ? 'full' : 'fit'}
      >
        <div
          className={cn('relative h-full w-full rounded-lg', {
            'overflow-y-auto': scroll,
          })}
        >
          <div
            style={{
              height: virtualizationOptions.enabled
                ? `${virtualizer.getTotalSize() + 72}px`
                : undefined,
            }}
          >
            <Table
              width={fullWidth ? 'full' : 'fit'}
              id={tableId}
              dataTestId={`${tableId}-table`}
              className={cn({
                'absolute inset-0': scroll,
              })}
            >
              <Table.Head
                background="container-secondary"
                className={cn(themeBackgroundBlurMap['table-head-blur'], {
                  'sticky top-0': scroll,
                  'z-10': scroll,
                })}
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row
                    key={headerGroup.id}
                    dataTestId={`${tableId}-table-header-row`}
                    className="h-18"
                  >
                    {headerGroup.headers.map((header) => (
                      <Table.HeaderCell
                        key={header.id}
                        className={cn(
                          header.column.columnDef.meta?.className || '',
                          {
                            'cursor-pointer select-none':
                              header.column.getCanSort(),
                          }
                        )}
                        canSort={header.column.getCanSort()}
                        direction={header.column.getIsSorted()}
                        onClick={header.column.getToggleSortingHandler()}
                        dataTestId={`${tableId}-table-header-cell-${header.id}`}
                        dataTrackingId={`${tableId}-table-header-cell-${header.id}`}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body className="h-full" background={null}>
                {virtualizationOptions.enabled ? (
                  <VirtualRows
                    virtualizer={virtualizer}
                    tableId={tableId}
                    rowComponent={rowComponent}
                    rowIdentifier={rowIdentifier}
                    rows={rows}
                    onClick={handleRowClick}
                  />
                ) : (
                  <DefaultRows
                    tableId={tableId}
                    rowComponent={rowComponent}
                    rowIdentifier={rowIdentifier}
                    rows={rows}
                    onClick={handleRowClick}
                  />
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </Surface>
    </VirtualizationProvider>
  )
}
