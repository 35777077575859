'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'
import type { PropsWithChildren } from 'react'

import {
  type FontSize,
  type HeadingSize,
  type TextOptions,
  getTextBaseClasses,
} from '@mntn-dev/ui-theme'
import { type TestIds, getTestProps } from '../../utils/testing.ts'

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4'

const levelDefaultSizeMap: Record<HeadingLevel, HeadingSize> = {
  h1: '4xl',
  h2: '3xl',
  h3: '2xl',
  h4: 'xl',
}

type HeadingProps = Readonly<
  PropsWithChildren<
    TestIds &
      React.HTMLAttributes<HTMLElement> &
      Omit<TextOptions, 'fontSize'> & {
        addPeriod?: boolean
        fontSize?: HeadingSize
        periodFontSize?: FontSize
        level?: HeadingLevel
      }
  >
>

const Heading = ({
  addPeriod,
  children,
  className,
  dataTestId,
  dataTrackingId,
  level = 'h1',
  periodFontSize,
  onClick,
  ...textOptions
}: HeadingProps) => {
  const HeadingTag = level as React.ElementType

  return (
    <HeadingTag
      className={cn(
        getTextBaseClasses({
          ...textOptions,
          textColor: textOptions.textColor ?? 'primary',
          fontFamily: textOptions.fontFamily ?? 'heading',
          fontSize: textOptions.fontSize ?? levelDefaultSizeMap[level],
          fontWeight: textOptions.fontWeight ?? 'bold',
        }),
        'leading-tight',
        className,
        onClick ? 'cursor-pointer' : null
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
      onClick={onClick}
    >
      {children}
      {addPeriod && (
        <span
          className={`${getTextBaseClasses({
            ...textOptions,
            fontSize:
              periodFontSize ??
              textOptions.fontSize ??
              levelDefaultSizeMap[level],
          })} text-primary-blue`}
        >
          .
        </span>
      )}
    </HeadingTag>
  )
}

export { Heading, type HeadingProps, type HeadingLevel }
