import type { PropsWithChildren } from 'react'
import { Heading, type HeadingProps } from '../../heading/heading.tsx'

export type CardTitleProps = PropsWithChildren<
  Pick<HeadingProps, 'dataTestId' | 'dataTrackingId' | 'fontSize'>
>

const CardTitle = ({
  children,
  fontSize = '2xl',
  ...testIds
}: CardTitleProps) => (
  <Heading fontSize={fontSize} {...testIds}>
    {children}
  </Heading>
)

export { CardTitle }
