import type { TupleToUnion } from 'type-fest'

export const fontWeights = [
  'light',
  'normal',
  'medium',
  'semibold',
  'bold',
  'black',
] as const
export type FontWeight = TupleToUnion<typeof fontWeights>

export const fontWeightMap: Record<FontWeight, string> = {
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  black: 'font-black',
}
