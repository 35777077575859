'use client'

import { cn } from '@mntn-dev/ui-utilities'
import {
  type ChangeEvent,
  type HTMLAttributes,
  type KeyboardEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'

import { themeHoverRingColorMap, themeTextColorMap } from '@mntn-dev/ui-theme'
import { mergeRefs } from '@mntn-dev/utilities'

import { type TestIds, getTestProps } from '../../utils/testing.ts'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'
import { Icon } from '../icon/icon.tsx'

type EditableProps = HTMLAttributes<HTMLInputElement> &
  UseFormFieldControlProps<HTMLInputElement> &
  TestIds & {
    initialValue?: string
    onUpdate?: (value: string) => void
    placeholder: string
  }

const Editable = forwardRef<HTMLInputElement, EditableProps>(
  (
    {
      className,
      dataTestId,
      dataTrackingId,
      id,
      hasError: hasErrorProp,
      placeholder,
      readOnly,
      initialValue,
      onUpdate,
      onChange,
    },
    ref
  ) => {
    const { hasError, hasSuccess, hasWarning, ...fieldProps } =
      useFormFieldControl<HTMLInputElement>({
        id,
        hasError: hasErrorProp,
        dataTestId: `${dataTestId}-input`,
        dataTrackingId: `${dataTrackingId}-input`,
      })
    const innerRefEl = useRef<HTMLInputElement | undefined>(undefined)
    const innerRef = (el: HTMLInputElement) => {
      innerRefEl.current = el
      el?.focus()

      if (!editing) {
        setEditing(true)
      }
    }
    const inputRef = mergeRefs(ref, innerRef)

    const [editing, setEditing] = useState(false)
    const [currentValue, setCurrentValue] = useState<string>(initialValue ?? '')

    const previous = useRef(currentValue)

    const handleButtonClick = () => {
      setEditing((editing) => !editing)
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        setCurrentValue(previous.current)
        setEditing(false)
      }

      if (event.key === 'Enter') {
        setEditing(false)

        handleUpdate(currentValue)
      }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentValue(event.target.value)

      if (onChange) {
        onChange(event)
      }
    }

    const handleUpdate = (value: string) => {
      if (currentValue !== previous.current) {
        previous.current = currentValue

        if (onUpdate) {
          onUpdate(value)
        }
      }
    }

    useEffect(() => {
      function handleMouseDown(e: MouseEvent) {
        if (editing) {
          if (e.target !== innerRefEl.current) {
            setEditing(false)
            handleUpdate(currentValue)
          }
        }
      }
      window.addEventListener('mousedown', handleMouseDown, true)

      return () => {
        window.removeEventListener('mousedown', handleMouseDown, true)
      }
    })

    return editing || hasError ? (
      <input
        {...fieldProps}
        ref={inputRef}
        className={cn(
          'max-w-full rounded border-none bg-transparent p-0 text-4xl font-bold outline-none',
          themeTextColorMap.primary,
          {
            'focus:ring-primary-blue focus:ring-1': !hasError,
            'ring-primary-red focus:ring-primary-red ring-1': hasError,
          },
          className
        )}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={currentValue}
      />
    ) : (
      <div className="flex items-center gap-4">
        <button
          type="button"
          onClick={readOnly ? undefined : handleButtonClick}
          className={cn(
            'cursor-text rounded text-4xl font-bold hover:ring-1',
            {
              [themeTextColorMap.disabled]: !currentValue,
              [themeTextColorMap.primary]: currentValue,
              [themeHoverRingColorMap.muted]: !readOnly,
              'hover:ring-transparent': readOnly,
            },
            className
          )}
          {...getTestProps({
            dataTestId: dataTestId ?? `${dataTestId}-button`,
            dataTrackingId: dataTrackingId ?? `${dataTrackingId}-button`,
          })}
        >
          {currentValue === '' ? placeholder : currentValue}
        </button>
        {!readOnly && (
          <button type="button" onClick={handleButtonClick}>
            <Icon name="PencilSquareIcon" size="lg" color="info" />
          </button>
        )}
      </div>
    )
  }
)

export { Editable }
