import { z } from 'zod'

import {
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../review.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import {
  ProjectIdSchema,
  ReviewIdSchema,
  RoundIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PostProductionReviewBrandFeedbackSubmittedReviewStatusSchema =
  ReviewStatusSchema.extract(['concepting', 'resolved'])

export type PostProductionReviewBrandFeedbackSubmittedReviewStatus = z.infer<
  typeof PostProductionReviewBrandFeedbackSubmittedReviewStatusSchema
>

export const PostProductionReviewBrandFeedbackSubmittedRoundStatusSchema =
  RoundStatusSchema.exclude(['in_progress'])

export type PostProductionReviewBrandFeedbackSubmittedRoundStatus = z.infer<
  typeof PostProductionReviewBrandFeedbackSubmittedRoundStatusSchema
>

export const PostProductionReviewBrandFeedbackSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'post_production_review_brand_feedback_submitted',
    z
      .object({
        reviewId: ReviewIdSchema,
        projectId: ProjectIdSchema,
        reviewStatus:
          PostProductionReviewBrandFeedbackSubmittedReviewStatusSchema,
        roundId: RoundIdSchema,
        roundNumber: ReviewRoundNumberSchema,
        roundStatus:
          PostProductionReviewBrandFeedbackSubmittedRoundStatusSchema,
      })
      .merge(ActivityLinkTargets('project'))
  )
