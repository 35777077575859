'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import { getTestProps } from '../../../utils/testing.ts'

export const PageHeaderControls = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const { dataTestId, dataTrackingId } = useTestTagsContext()
  return (
    <div
      className={cn('flex content-end items-center gap-6', className)}
      {...getTestProps({
        dataTestId: `${dataTestId}-controls`,
        dataTrackingId: `${dataTrackingId}-controls`,
      })}
    >
      {children}
    </div>
  )
}
