'use client'

import type { PropsWithChildren } from 'react'
import { getTestProps, type TestIds } from '../../utils/testing.ts'
import { CollapsibleButton } from './collapsible-button.tsx'
import { CollapsiblePanel } from './collapsible-panel.tsx'
import { CollapsibleComment } from './collapsible-comment.tsx'
import { CollapsibleTitle } from './collapsible-title.tsx'
import { useCollapsible, CollapsibleProvider } from './use-collapsible.ts'

type CollapsibleProps = PropsWithChildren<
  {
    isOpen?: boolean
  } & TestIds
>

const Collapsible = ({
  dataTestId,
  dataTrackingId,
  children,
  isOpen = true,
}: CollapsibleProps) => {
  const collapsibleContext = useCollapsible({ isOpen })

  return (
    <CollapsibleProvider value={collapsibleContext}>
      <div {...getTestProps({ dataTestId, dataTrackingId })}>{children}</div>
    </CollapsibleProvider>
  )
}

const CollapsibleNamespace = Object.assign(Collapsible, {
  Button: CollapsibleButton,
  Comment: CollapsibleComment,
  Panel: CollapsiblePanel,
  Title: CollapsibleTitle,
})

export { CollapsibleNamespace as Collapsible }
