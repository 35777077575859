'use client'

import type { PropsWithChildren } from 'react'

import type { ThemeTextColor } from '@mntn-dev/ui-theme'

import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import type { TestIds } from '../../../utils/testing.ts'
import { Button } from '../../buttons/button.tsx'

export const PageHeaderOverlineLink = ({
  onClick,
  textColor = 'primary',
  isLoading = false,
  children,
  dataTestId: dataTestIdProp,
  dataTrackingId: dataTrackingIdProp,
}: PropsWithChildren<
  {
    onClick: () => void
    isLoading?: boolean
    textColor?: ThemeTextColor
  } & TestIds
>) => {
  const {
    dataTestId: dataTestIdContext,
    dataTrackingId: dataTrackingIdContext,
  } = useTestTagsContext()
  const dataTestId = dataTestIdProp || `${dataTestIdContext}-overline-link`
  const dataTrackingId =
    dataTrackingIdProp || `${dataTrackingIdContext}-overline-link`

  return (
    <Button
      size="xs"
      variant="text"
      onClick={onClick}
      iconLeft="ArrowLeftIcon"
      textColor={textColor}
      loading={isLoading}
      className="-mr-2"
      dataTestId={dataTestId}
      dataTrackingId={dataTrackingId}
    >
      {children}
    </Button>
  )
}
