import type { EmptyObject, OverrideProperties, SimplifyDeep } from 'type-fest'
import { z } from 'zod'
import {
  type ActivityDomainSelectModel,
  ActivityDomainSelectModelSchema,
  type ActivityType,
} from './activity.models.ts'
import {
  type AgencyDomainSelectModel,
  AgencyDomainSelectModelSchema,
  type AgencyProfileDomainSelectModel,
  AgencyProfileDomainSelectModelSchema,
} from './agency.models.ts'
import {
  type AgreementDomainSelectModel,
  AgreementDomainSelectModelSchema,
} from './agreement.models.ts'
import {
  type BidDomainSelectModel,
  BidDomainSelectModelSchema,
} from './bid.models.ts'
import {
  type BrandDomainSelectModel,
  BrandDomainSelectModelSchema,
} from './brand.models.ts'
import {
  type DeliverableDomainSelectModel,
  DeliverableDomainSelectModelSchema,
} from './deliverable.models.ts'
import {
  type FileDomainSelectModel,
  FileDomainSelectModelSchema,
} from './file.models.ts'
import {
  type NotificationDomainSelectModel,
  NotificationDomainSelectModelSchema,
} from './notification.models.ts'
import {
  type OfferDomainSelectModel,
  OfferDomainSelectModelSchema,
} from './offer.models.ts'
import {
  type OrganizationDomainSelectModel,
  OrganizationDomainSelectModelSchema,
} from './organization.models.ts'
import {
  type PackageServiceViewDomainSelectModel,
  PackageServiceViewDomainSelectModelSchema,
} from './package-service.models.ts'
import {
  type PackageDomainSelectModel,
  PackageDomainSelectModelSchema,
} from './package.models.ts'
import {
  type ProjectServiceDomainSelectModel,
  ProjectServiceDomainSelectModelSchema,
} from './project-service.models.ts'
import {
  ProjectDomainSelectModelSchema,
  type ProjectViewDomainSelectModel,
  ProjectViewDomainSelectModelSchema,
} from './project.models.ts'
import {
  type ProofDomainSelectModel,
  ProofDomainSelectModelSchema,
} from './proof.models.ts'
import {
  type ReviewDomainSelectModel,
  ReviewDomainSelectModelSchema,
} from './review.models.ts'
import {
  type RoundDomainSelectModel,
  RoundDomainSelectModelSchema,
} from './round.models.ts'
import {
  type ServiceDomainSelectModel,
  ServiceDomainSelectModelSchema,
} from './service.models.ts'
import {
  type SurveyDomainSelectModel,
  SurveyDomainSelectModelSchema,
} from './survey.models.ts'
import {
  TagCategorySchema,
  type TagDomainSelectModel,
  TagDomainSelectModelSchema,
} from './tag.models.ts'
import {
  type TeamDomainSelectModel,
  TeamDomainSelectModelSchema,
} from './team.models.ts'
import {
  type UserDomainSelectModel,
  UserDomainSelectModelSchema,
} from './user.models.ts'
import {
  type WatchDomainSelectModel,
  WatchDomainSelectModelSchema,
} from './watch.models.ts'

/**
 * Typescript does not allow circular references between objects so the validation can only
 * be done one level deep which is sufficient for testing and mapping purposes. The relations
 * are defined using .passThrough() so that the validation won't fail when there are nested
 * relations. The following functions are used to define the relations for consistency.
 */

/**
 * A helper function to define a one-to-one relation
 * @param schema The referenced query model schema
 * @returns A schema with .passthrough() applied (so that nested relations won't fail validation)
 */
type One<To> = To

const One = <Schema extends z.ZodRawShape>(schema: z.ZodObject<Schema>) =>
  schema.passthrough()

/**
 * A helper function to define a one-to-many relation
 * @param schema The referenced query model schema
 * @returns A schema with .passthrough() applied (so that nested relations won't fail validation) as an array
 */
type Many<To> = Array<To>

const Many = <Schema extends z.ZodRawShape>(schema: z.ZodObject<Schema>) =>
  z.array(One(schema))

/**
 * A helper function to define the relations for a specific query model
 * @param relations The set of relations for a specific query model
 * @returns A schema that does not allow any additional properties and allows the relations to be optional
 */
type Relations<Links> = Partial<Links>

const Relations = <Links extends z.ZodRawShape>(links: Links) =>
  z.object(links).partial()

/**
 * TagDomainQueryModel
 */
export const TagDomainQueryModelSchema = TagDomainSelectModelSchema.merge(
  Relations({})
)

export type TagDomainQueryModel = TagDomainSelectModel & Relations<EmptyObject>

export const TagsByCategoryMapSchema = z.record(
  TagCategorySchema,
  z.array(TagDomainQueryModelSchema)
)

export type TagsByCategoryMap = z.infer<typeof TagsByCategoryMapSchema>

/**
 * ActivityDomainQueryModel
 */
export const ActivityDomainQueryModelSchema =
  ActivityDomainSelectModelSchema.merge(
    Relations({
      actor: One(UserDomainSelectModelSchema),
    })
  )

export type ActivityDomainQueryModel<Type extends ActivityType = ActivityType> =
  ActivityDomainSelectModel<Type> &
    Relations<{
      actor: One<UserDomainQueryModel>
    }>

/**
 * AgencyProfileDomainQueryModel
 */
export const AgencyProfileDomainQueryModelSchema =
  AgencyProfileDomainSelectModelSchema.merge(
    Relations({
      logo: One(AgencyProfileDomainSelectModelSchema),
    })
  )

export type AgencyProfileDomainQueryModel = AgencyProfileDomainSelectModel &
  Relations<{
    logo: One<FileDomainQueryModel>
  }>

/**
 * AgencyDomainQueryModel
 */
export const AgencyDomainQueryModelSchema = AgencyDomainSelectModelSchema.merge(
  Relations({
    bids: Many(BidDomainSelectModelSchema),
    files: Many(FileDomainSelectModelSchema),
    profile: One(AgencyProfileDomainSelectModelSchema),
    projects: Many(ProjectViewDomainSelectModelSchema),
    users: Many(UserDomainSelectModelSchema),
  })
)

export type AgencyDomainQueryModel = AgencyDomainSelectModel &
  Relations<{
    bids: Many<BidDomainQueryModel>
    files: Many<FileDomainQueryModel>
    profile: One<AgencyProfileDomainQueryModel>
    projects: Many<ProjectDomainQueryModel>
    users: Many<UserDomainQueryModel>
  }>

/**
 * AgreementDomainQueryModel
 */
export const AgreementDomainQueryModelSchema =
  AgreementDomainSelectModelSchema.merge(Relations({}))

export type AgreementDomainQueryModel = AgreementDomainSelectModel &
  Relations<EmptyObject>

/**
 * BidDomainQueryModel
 */
export const BidDomainQueryModelSchema = BidDomainSelectModelSchema.merge(
  Relations({
    agency: One(AgencyDomainSelectModelSchema),
    files: Many(FileDomainSelectModelSchema),
    project: One(ProjectViewDomainSelectModelSchema),
  })
)

export type BidDomainQueryModel = BidDomainSelectModel &
  Relations<{
    agency: One<AgencyDomainQueryModel>
    files: Many<FileDomainQueryModel>
    project: One<ProjectDomainQueryModel>
  }>

/**
 * BrandDomainQueryModel
 */
export const BrandDomainQueryModelSchema = BrandDomainSelectModelSchema.merge(
  Relations({
    files: Many(FileDomainSelectModelSchema),
    projects: Many(ProjectViewDomainSelectModelSchema),
    users: Many(UserDomainSelectModelSchema),
  })
)

export type BrandDomainQueryModel = BrandDomainSelectModel &
  Relations<{
    files: Many<FileDomainQueryModel>
    projects: Many<ProjectDomainQueryModel>
    users: Many<UserDomainQueryModel>
  }>

/**
 * DeliverableDomainQueryModel
 */
export const DeliverableDomainQueryModelSchema =
  DeliverableDomainSelectModelSchema.merge(
    Relations({
      file: One(FileDomainSelectModelSchema),
      project: One(ProjectViewDomainSelectModelSchema),
      service: One(ProjectServiceDomainSelectModelSchema),
    })
  )

export type DeliverableDomainQueryModel = DeliverableDomainSelectModel &
  Relations<{
    file: One<FileDomainQueryModel>
    project: One<ProjectDomainQueryModel>
    service: One<ProjectServiceDomainQueryModel>
  }>

/**
 * FileDomainQueryModel
 */
export const FileDomainQueryModelSchema = FileDomainSelectModelSchema.merge(
  Relations({
    owner: One(UserDomainSelectModelSchema),
    project: One(ProjectDomainSelectModelSchema),
    service: One(ProjectServiceDomainSelectModelSchema),
    tags: TagsByCategoryMapSchema,
  })
)

export type FileDomainQueryModel = FileDomainSelectModel &
  Relations<{
    owner: One<UserDomainQueryModel>
    project: One<ProjectDomainQueryModel>
    service: One<ProjectServiceDomainQueryModel>
    tags: TagsByCategoryMap
  }>

/**
 * NotificationDomainQueryModel
 */
export const NotificationDomainQueryModelSchema =
  NotificationDomainSelectModelSchema.merge(
    Relations({
      activity: One(ActivityDomainSelectModelSchema),
      recipient: One(UserDomainSelectModelSchema),
    })
  )

export type NotificationDomainQueryModel = NotificationDomainSelectModel &
  Relations<{
    activity: One<ActivityDomainQueryModel>
    recipient: One<UserDomainQueryModel>
  }>

/**
 * OfferDomainQueryModel
 */
export const OfferDomainQueryModelSchema = OfferDomainSelectModelSchema.merge(
  Relations({
    agency: One(AgencyDomainSelectModelSchema),
    acceptor: One(UserDomainSelectModelSchema),
    project: One(ProjectViewDomainSelectModelSchema),
  })
)

export type OfferDomainQueryModel = OfferDomainSelectModel &
  Relations<{
    agency: One<AgencyDomainQueryModel>
    acceptor: One<UserDomainQueryModel>
    project: One<ProjectDomainQueryModel>
  }>

/**
 * OrganizationDomainQueryModel
 */
export const OrganizationDomainQueryModelSchema =
  OrganizationDomainSelectModelSchema.merge(Relations({}))

export type OrganizationDomainQueryModel = OrganizationDomainSelectModel &
  Relations<EmptyObject>

/**
 * PackageDomainQueryModel
 */
export const PackageDomainQueryModelSchema =
  PackageDomainSelectModelSchema.merge(
    Relations({
      services: Many(PackageServiceViewDomainSelectModelSchema),
    })
  )

export type PackageDomainQueryModel = PackageDomainSelectModel &
  Relations<{
    services: Many<PackageServiceDomainQueryModel>
  }>

/**
 * PackageServiceDomainQueryModel
 */
export const PackageServiceDomainQueryModelSchema =
  PackageServiceViewDomainSelectModelSchema.merge(Relations({}))

export type PackageServiceDomainQueryModel =
  PackageServiceViewDomainSelectModel & Relations<EmptyObject>

/**
 * ProjectDomainQueryModel
 */
export const ProjectDomainQueryModelSchema =
  ProjectViewDomainSelectModelSchema.merge(
    Relations({
      agency: One(AgencyDomainSelectModelSchema),
      acceptor: One(UserDomainSelectModelSchema),
      brand: One(BrandDomainSelectModelSchema),
      owner: One(UserDomainSelectModelSchema),
      activity: Many(ActivityDomainSelectModelSchema),
      services: Many(ProjectServiceDomainSelectModelSchema),
      offers: Many(OfferDomainSelectModelSchema),
      files: Many(FileDomainSelectModelSchema),
      tags: TagsByCategoryMapSchema,
      review: One(ReviewDomainSelectModelSchema),
      deliverables: Many(DeliverableDomainSelectModelSchema),
      thumbnail: One(FileDomainSelectModelSchema),
      bids: Many(BidDomainSelectModelSchema),
    })
  )

export type ProjectDomainQueryModel = ProjectViewDomainSelectModel &
  Relations<{
    agency: One<AgencyDomainQueryModel>
    acceptor: One<UserDomainQueryModel>
    brand: One<BrandDomainQueryModel>
    owner: One<UserDomainQueryModel>
    activity: Many<ActivityDomainQueryModel>
    services: Many<ProjectServiceDomainQueryModel>
    offers: Many<OfferDomainQueryModel>
    files: Many<FileDomainQueryModel>
    tags: TagsByCategoryMap
    review: One<ReviewDomainQueryModel>
    deliverables: Many<DeliverableDomainQueryModel>
    thumbnail: One<FileDomainQueryModel>
    bids: Many<BidDomainQueryModel>
  }>

/**
 * ProjectServiceDomainQueryModel
 */
export const ProjectServiceDomainQueryModelSchema =
  ProjectServiceDomainSelectModelSchema.merge(
    Relations({
      files: Many(FileDomainSelectModelSchema),
      project: One(ProjectViewDomainSelectModelSchema),
      deliverables: Many(DeliverableDomainSelectModelSchema),
      review: One(ReviewDomainSelectModelSchema),
    })
  )

export type ProjectServiceDomainQueryModel = ProjectServiceDomainSelectModel &
  Relations<{
    files: Many<FileDomainQueryModel>
    project: One<ProjectDomainQueryModel>
    deliverables: Many<DeliverableDomainQueryModel>
    review: One<ReviewDomainQueryModel>
  }>

/**
 * ProofDomainQueryModel
 */
export const ProofDomainQueryModelSchema = ProofDomainSelectModelSchema.merge(
  Relations({
    deliverable: One(DeliverableDomainSelectModelSchema),
    file: One(FileDomainSelectModelSchema),
    round: One(RoundDomainSelectModelSchema),
  })
)

export type ProofDomainQueryModel = SimplifyDeep<
  ProofDomainSelectModel &
    Relations<{
      deliverable: One<DeliverableDomainQueryModel>
      file: One<FileDomainQueryModel>
      round: One<RoundDomainQueryModel>
    }>
>

/**
 * ReviewDomainQueryModel
 */
export const ReviewDomainQueryModelSchema = ReviewDomainSelectModelSchema.merge(
  Relations({
    project: One(ProjectDomainQueryModelSchema),
    service: One(ProjectServiceDomainSelectModelSchema),
    rounds: Many(RoundDomainSelectModelSchema),
  })
)

export type ReviewDomainQueryModel = ReviewDomainSelectModel &
  Relations<{
    project: One<ProjectDomainQueryModel>
    service: One<ProjectServiceDomainQueryModel>
    rounds: Many<RoundDomainQueryModel>
  }>

/**
 * RoundDomainQueryModel
 */
export const RoundDomainQueryModelSchema = RoundDomainSelectModelSchema.merge(
  Relations({
    proofs: Many(ProofDomainSelectModelSchema),
  })
).extend({
  proposal: RoundDomainSelectModelSchema.shape.proposal.extend({
    submitted: RoundDomainSelectModelSchema.shape.proposal.shape.submitted
      .unwrap()
      .merge(
        Relations({
          actor: One(UserDomainSelectModelSchema),
        })
      )
      .optional(),
  }),
  feedback: RoundDomainSelectModelSchema.shape.feedback.extend({
    submitted: RoundDomainSelectModelSchema.shape.feedback.shape.submitted
      .unwrap()
      .merge(
        Relations({
          actor: One(UserDomainSelectModelSchema),
        })
      )
      .optional(),
  }),
})

export type RoundDomainQueryModel = SimplifyDeep<
  OverrideProperties<
    RoundDomainSelectModel &
      Relations<{
        proofs: Many<ProofDomainQueryModel>
      }>,
    {
      proposal: OverrideProperties<
        RoundDomainSelectModel['proposal'],
        Partial<{
          submitted: RoundDomainSelectModel['proposal']['submitted'] &
            Relations<{
              actor: One<UserDomainQueryModel>
            }>
        }>
      >
      feedback: OverrideProperties<
        RoundDomainSelectModel['feedback'],
        Partial<{
          submitted: RoundDomainSelectModel['feedback']['submitted'] &
            Relations<{
              actor: One<UserDomainQueryModel>
            }>
        }>
      >
    }
  >
>

/**
 * ServiceDomainQueryModel
 */
export const ServiceDomainQueryModelSchema =
  ServiceDomainSelectModelSchema.merge(Relations({}))

export type ServiceDomainQueryModel = ServiceDomainSelectModel &
  Relations<EmptyObject>

/**
 * SurveyDomainQueryModel
 */
export const SurveyDomainQueryModelSchema = SurveyDomainSelectModelSchema.merge(
  Relations({})
)

export type SurveyDomainQueryModel = SurveyDomainSelectModel &
  Relations<EmptyObject>

/**
 * TeamDomainQueryModel
 */
export const TeamDomainQueryModelSchema = TeamDomainSelectModelSchema.merge(
  Relations({
    organization: One(OrganizationDomainSelectModelSchema),
    files: Many(FileDomainSelectModelSchema),
    projects: Many(ProjectViewDomainSelectModelSchema),
    users: Many(UserDomainSelectModelSchema),
  })
)

export type TeamDomainQueryModel = TeamDomainSelectModel &
  Relations<{
    organization: One<OrganizationDomainQueryModel>
    files: Many<FileDomainQueryModel>
    projects: Many<ProjectDomainQueryModel>
    users: Many<UserDomainQueryModel>
  }>

/**
 * UserDomainQueryModel
 */
export const UserDomainQueryModelSchema = UserDomainSelectModelSchema.merge(
  Relations({
    avatar: One(FileDomainSelectModelSchema),
    agency: One(AgencyDomainSelectModelSchema),
    brand: One(BrandDomainSelectModelSchema),
    files: Many(FileDomainSelectModelSchema),
    organization: One(OrganizationDomainSelectModelSchema),
    tags: TagsByCategoryMapSchema,
    team: One(TeamDomainSelectModelSchema),
  })
)

export type UserDomainQueryModel = UserDomainSelectModel &
  Relations<{
    avatar: One<FileDomainQueryModel>
    agency: One<AgencyDomainQueryModel>
    brand: One<BrandDomainQueryModel>
    files: Many<FileDomainQueryModel>
    organization: One<OrganizationDomainQueryModel>
    tags: TagsByCategoryMap
    team: One<TeamDomainQueryModel>
  }>

/**
 * WatchDomainQueryModel
 */
export const WatchDomainQueryModelSchema = WatchDomainSelectModelSchema.merge(
  Relations({
    user: One(UserDomainSelectModelSchema),
  })
)

export type WatchDomainQueryModel = WatchDomainSelectModel &
  Relations<{
    user: One<UserDomainQueryModel>
  }>
