import { z } from 'zod'

import {
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../review.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import {
  ProjectIdSchema,
  ReviewIdSchema,
  RoundIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PostProductionReviewMakerProposalSubmittedReviewStatusSchema =
  ReviewStatusSchema.extract(['reviewing', 'resolving'])

export type PostProductionReviewMakerProposalSubmittedReviewStatus = z.infer<
  typeof PostProductionReviewMakerProposalSubmittedReviewStatusSchema
>

export const PostProductionReviewMakerProposalSubmittedRoundStatusSchema =
  RoundStatusSchema.extract(['in_progress'])

export type PostProductionReviewMakerProposalSubmittedRoundStatus = z.infer<
  typeof PostProductionReviewMakerProposalSubmittedRoundStatusSchema
>

export const PostProductionReviewMakerProposalSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'post_production_review_maker_proposal_submitted',
    z
      .object({
        reviewId: ReviewIdSchema,
        projectId: ProjectIdSchema,
        reviewStatus:
          PostProductionReviewMakerProposalSubmittedReviewStatusSchema,
        roundId: RoundIdSchema,
        roundNumber: ReviewRoundNumberSchema,
        roundStatus:
          PostProductionReviewMakerProposalSubmittedRoundStatusSchema,
      })
      .merge(ActivityLinkTargets('project'))
  )
