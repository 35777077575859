'use client'
import { getLocale } from '@mntn-dev/i18n'
import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { formatDate } from '@mntn-dev/utilities'
import { type JSX, useRef } from 'react'

import type { LanguageId } from '@mntn-dev/domain-types'
import type { PropsWithChildren } from 'react'
import { useScrollIntoView } from '../../hooks/index.ts'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { Avatar } from '../avatar/index.ts'
import { Icon } from '../icon/index.ts'
import { Stack } from '../stack/index.ts'

type FeedItemProps = Readonly<
  TestIds &
    PropsWithChildren<{
      avatar?: JSX.Element
      timestamp: Date
      languageId: LanguageId
      indicator?: boolean
      scroll?: boolean
    }>
>

const FeedItem = ({
  avatar,
  timestamp,
  dataTestId,
  dataTrackingId,
  languageId,
  indicator,
  scroll,
  children,
}: FeedItemProps) => {
  const locale = getLocale(languageId)

  const innerRef = useRef<HTMLLIElement | undefined>(undefined)
  const { mergedRef } = useScrollIntoView<HTMLLIElement>({
    scroll,
    innerRef,
  })

  return (
    <li
      ref={mergedRef as React.Ref<HTMLLIElement>}
      className="relative flex gap-x-4 p-8 pr-24"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {indicator && (
        <Icon
          name="CircleIcon"
          size="2xs"
          color="negative"
          className="absolute top-2 left-2"
        />
      )}
      <Avatar>{avatar}</Avatar>
      <Stack direction="col" width="full">
        <time
          dateTime={timestamp.toLocaleDateString()}
          className={`${themeTextColorMap.disabled} text-sm font-medium first-letter:uppercase`}
        >
          {formatDate(timestamp, 'relative-time', locale)}
        </time>
        <Stack direction="col" gap="4" width="full">
          {children}
        </Stack>
      </Stack>
    </li>
  )
}

export { FeedItem, type FeedItemProps }
