import type { TupleToUnion } from 'type-fest'

const themeGlowNames = [
  'default',
  'default-lg',
  'default-inner',
  'info',
  'info-lg',
  'info-inner',
  'negative',
  'negative-lg',
  'negative-inner',
  'notice',
  'notice-lg',
  'notice-inner',
  'positive',
  'positive-lg',
  'positive-inner',
] as const
type ThemeGlow = TupleToUnion<typeof themeGlowNames>

const themeHoverGlowNames = [
  'info',
  'info-lg',
  'info-inner',
  'negative',
  'negative-lg',
  'negative-inner',
  'notice',
  'notice-lg',
  'notice-inner',
  'positive',
  'positive-lg',
  'positive-inner',
] as const
type ThemeHoverGlow = TupleToUnion<typeof themeHoverGlowNames>

const themeGlowMap: Record<ThemeGlow, string> = {
  default: 'shadow-glow-blue',
  'default-lg': 'shadow-glow-blue-lg',
  'default-inner': 'shadow-glow-blue-inner',
  info: 'shadow-glow-blue',
  'info-lg': 'shadow-glow-blue-lg',
  'info-inner': 'shadow-glow-blue-inner',
  negative: 'shadow-glow-red',
  'negative-lg': 'shadow-glow-red-lg',
  'negative-inner': 'shadow-glow-red-inner',
  notice: 'shadow-glow-purple',
  'notice-lg': 'shadow-glow-purple-lg',
  'notice-inner': 'shadow-glow-purple-inner',
  positive: 'shadow-glow-green',
  'positive-lg': 'shadow-glow-green-lg',
  'positive-inner': 'shadow-glow-green-inner',
}

const themeHoverGlowMap: Record<ThemeHoverGlow, string> = {
  info: 'hover:shadow-glow-blue',
  'info-lg': 'hover:shadow-glow-blue-lg',
  'info-inner': 'hover:shadow-glow-blue-inner',
  negative: 'hover:shadow-glow-red',
  'negative-lg': 'hover:shadow-glow-red-lg',
  'negative-inner': 'hover:shadow-glow-red-inner',
  notice: 'hover:shadow-glow-purple',
  'notice-lg': 'hover:shadow-glow-purple-lg',
  'notice-inner': 'hover:shadow-glow-purple-inner',
  positive: 'hover:shadow-glow-green',
  'positive-lg': 'hover:shadow-glow-green-lg',
  'positive-inner': 'hover:shadow-glow-green-inner',
}

export {
  themeGlowMap,
  themeHoverGlowMap,
  themeGlowNames,
  themeHoverGlowNames,
  type ThemeGlow,
  type ThemeHoverGlow,
}
