import type { TupleToUnion } from 'type-fest'

export const textSizes = ['3xs', '2xs', 'xs', 'sm', 'med', 'lg'] as const
export type TextSize = TupleToUnion<typeof textSizes>

export const headingSizes = [
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  'display',
  'displayXl',
] as const
export type HeadingSize = TupleToUnion<typeof headingSizes>

export const fontSizes = [...textSizes, ...headingSizes] as const
export type FontSize = TupleToUnion<typeof fontSizes>

export const fontSizeMap: Record<FontSize, string> = {
  '3xs': 'text-3xs',
  '2xs': 'text-2xs',
  xs: 'text-xs',
  sm: 'text-sm',
  med: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
  display: 'text-display',
  displayXl: 'text-displayXl',
}

export const rootRelativeFontSizeMap: Record<FontSize, number> = {
  '3xs': 0.5,
  '2xs': 0.625,
  xs: 0.75,
  sm: 0.875,
  med: 1,
  lg: 1.125,
  xl: 1.25,
  '2xl': 1.5,
  '3xl': 1.875,
  '4xl': 2.25,
  '5xl': 3,
  display: 4.5,
  displayXl: 6.25,
}

export const rootFontSize = 16

export const fontSizeToPixels = (value: FontSize): number => {
  return rootRelativeFontSizeMap[value] * rootFontSize
}
