'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { useSizing } from '../../hooks/use-sizing.ts'
import { getTestProps } from '../../utils/testing.ts'
import { AnimationFrame } from '../animation/animation-frame.tsx'
import { Heading } from '../heading/heading.tsx'
import { PlayerControlButton } from './player-control-button.tsx'
import { Timeline } from './timeline.tsx'
import type { AudioPlayerProps } from './types.tsx'
import { useAudioPlayer } from './use-audio-player.ts'

const AudioPlayer = ({
  dataTestId,
  dataTrackingId,
  src,
  name,
  ...props
}: AudioPlayerProps) => {
  const {
    currentTime,
    duration,
    status,
    isPlaying,
    audioRef,
    handleAudioFrame,
    handlePlayerControlClick,
    handleDragStart,
    handleDragEnd,
    handleSeek,
    handleSeekEnd,
    handleEnded,
    handlePause,
    handlePlay,
    handleLoadedMetadata,
    handleError,
  } = useAudioPlayer(src)

  const sizing = useSizing({
    ...props,
  })

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-2',
        ...Object.values(sizing)
      )}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <PlayerControlButton status={status} onClick={handlePlayerControlClick} />

      <Heading level="h4" textColor="primary">
        {name}
      </Heading>

      <Timeline
        currentTime={currentTime}
        duration={duration}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onSeek={handleSeek}
        onSeekEnd={handleSeekEnd}
      />

      {/* Only mount the useAnimationFrame hook while playing audio */}
      {isPlaying && <AnimationFrame onFrame={handleAudioFrame} />}

      {/* biome-ignore lint/a11y/useMediaCaption: we aren't using the media captions feature */}
      <audio
        src={src}
        ref={audioRef}
        onEnded={handleEnded}
        onPause={handlePause}
        onPlay={handlePlay}
        onLoadedMetadata={handleLoadedMetadata}
        onError={handleError}
      />
    </div>
  )
}

export { AudioPlayer, type AudioPlayerProps }
