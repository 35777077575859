'use client'

import { Children, type PropsWithChildren } from 'react'
import { Heading, type HeadingProps } from '../heading/heading.tsx'

const ModalHeadingBreadcrumbs = ({
  children,
  fontSize = '2xl',
}: PropsWithChildren<HeadingProps>) => {
  return (
    <>
      {Children.map(children, (child) => (
        <>
          <Heading fontSize={fontSize} textColor="info" className="px-2 inline">
            /
          </Heading>
          {child}
        </>
      ))}
    </>
  )
}

export { ModalHeadingBreadcrumbs }
