'use client'

import i18next, { type KeyPrefix } from 'i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import {
  type FallbackNs,
  Trans as TransOriginal,
  type UseTranslationOptions,
  initReactI18next,
  useTranslation as useTranslationOriginal,
} from 'react-i18next'

import { type LanguageId, LanguageIds } from '@mntn-dev/domain-types'
import { logger } from '@mntn-dev/logger'
import { runningOnServer } from '@mntn-dev/utilities'

import { getOptions } from './get-options.ts'
import { cookieName } from './settings.ts'
import { changeLanguage } from './utilities/change-language.ts'
import { getLocale } from './utilities/get-locale.ts'

import type { TranslationNamespace } from './types.ts'

export const Trans = TransOriginal

// initialize the i18next instance

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./locales/${language}/${namespace}.json`)
    )
  )
  .init({
    ...getOptions(), // only pass in options if they are not defaults
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    preload: runningOnServer() ? LanguageIds : [],
    interpolation: { escapeValue: false },
  })
  .catch((error) => {
    logger.error('Error initializing i18next', { error })
  })

/**
 * Returns a `t` translation function and an `i18n` object.
 *
 * This function is a wrapper around `react-i18next`'s `useTranslation` hook.
 *
 * @param ns TranslationNamespace
 * @param options TranslationOptions
 * @returns An object with a `t` function and an `i18n` object
 */
export function useTranslation<
  Ns extends TranslationNamespace,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(ns?: Ns, options?: UseTranslationOptions<KPrefix>) {
  const wrappedResult = useTranslationOriginal<Ns, KPrefix>(ns, options)
  return wrappedResult
}

export const useLanguage = () => useTranslation().i18n.language as LanguageId

export const useLocale = () => getLocale(useLanguage())

export const useChangeLanguage = (lng: LanguageId) => {
  const [cookies, setCookie] = useCookies([cookieName])
  const wrappedResult = useTranslationOriginal()

  const { i18n } = wrappedResult

  useEffect(() => {
    if (!lng || i18n.resolvedLanguage === lng) {
      return
    }

    changeLanguage(i18n, lng)
  }, [lng, i18n])

  useEffect(() => {
    if (cookies.i18next === lng) {
      return
    }
    setCookie(cookieName, lng, { path: '/' })
  }, [lng, cookies.i18next, setCookie])

  if (runningOnServer() && lng && i18n.resolvedLanguage !== lng) {
    changeLanguage(i18n, lng)
  }

  return wrappedResult
}

export function LanguageChanger({ lng }: { lng: LanguageId }) {
  useChangeLanguage(lng)
  return null
}
