import type { TupleToUnion } from 'type-fest'

export const FillSvgIconNames = [
  'nav-bar-chart',
  'nav-bell',
  'nav-color-wheel',
  'nav-folder',
  'nav-packages',
  'nav-projects',
  'nav-users',
  'ArchiveFileIcon',
  'BigCheckIcon',
  'BigXIcon',
  'CelebrationIcon',
  'CircleIcon',
  'DotIcon',
  'EditIcon',
  'ErrorIcon',
  'ExclamationIcon',
  'InfoIcon',
  'LoadingDots',
  'ProductionCameraIcon',
  'SuccessIcon',
  'VideoIcon',
  'WarningIcon',
] as const

export type FillIconName = TupleToUnion<typeof FillSvgIconNames>

export const OutlineSvgIconNames = ['ArrowPathCircleIcon'] as const

export type OutlineSvgIconName = TupleToUnion<typeof OutlineSvgIconNames>

export const SvgIconNames = [
  ...FillSvgIconNames,
  ...OutlineSvgIconNames,
] as const

export type SvgIconName = TupleToUnion<typeof SvgIconNames>

export const isSvgIconName = (name: unknown): name is SvgIconName =>
  SvgIconNames.includes(name as SvgIconName)

export const isFillSvgIconName = (name: unknown): name is FillIconName =>
  FillSvgIconNames.includes(name as FillIconName)

export const isOutlineSvgIconName = (
  name: unknown
): name is OutlineSvgIconName =>
  OutlineSvgIconNames.includes(name as OutlineSvgIconName)
