import type { TupleToUnion } from 'type-fest'

export const absoluteLineHeights = [
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
] as const
export type AbsoluteLineHeight = TupleToUnion<typeof absoluteLineHeights>

export const relativeLineHeights = [
  'none',
  'tight',
  'snug',
  'normal',
  'relaxed',
  'loose',
] as const
export type RelativeLineHeight = TupleToUnion<typeof relativeLineHeights>

export const lineHeights = [
  ...absoluteLineHeights,
  ...relativeLineHeights,
] as const
export type LineHeight = TupleToUnion<typeof lineHeights>

export const lineHeightMap: Record<LineHeight, string> = {
  '3': 'leading-3',
  '4': 'leading-4',
  '5': 'leading-5',
  '6': 'leading-6',
  '7': 'leading-7',
  '8': 'leading-8',
  '9': 'leading-9',
  '10': 'leading-10',
  none: 'leading-none',
  tight: 'leading-tight',
  snug: 'leading-snug',
  normal: 'leading-normal',
  relaxed: 'leading-relaxed',
  loose: 'leading-loose',
}

const isRelativeLineHeight = (
  value: LineHeight
): value is RelativeLineHeight => {
  return relativeLineHeights.includes(value as RelativeLineHeight)
}

const relativeLineHeightMap: Record<RelativeLineHeight, number> = {
  none: 1,
  tight: 1.25,
  snug: 1.375,
  normal: 1.5,
  relaxed: 1.625,
  loose: 2,
}

export const getLineHeightValue = (
  value: LineHeight
): { value: number; relative: boolean } => {
  if (isRelativeLineHeight(value)) {
    return { value: relativeLineHeightMap[value], relative: true }
  }

  return { value: Number.parseFloat(value) * 4, relative: false }
}
