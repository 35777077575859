import type { TupleToUnion } from 'type-fest'
import type { ThemeGapValue } from './gap.ts'

const spacingNames = [
  '0',
  'px',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '20',
  '24',
  '28',
  '32',
  '36',
  '40',
  '44',
  '48',
  '52',
  '56',
  '60',
  '64',
  '72',
  '80',
  '96',
  '112',
  '128',
  '144',
  '160',
  '176',
  '192',
  '222',
] as const
type ThemeSpacing = TupleToUnion<typeof spacingNames>

const spacingToPixels = (value: ThemeSpacing | ThemeGapValue): number => {
  return value === 'px' ? 1 : Number.parseFloat(value) * 4
}

export { spacingNames, type ThemeSpacing, spacingToPixels }
