import { z } from 'zod'

import { ProjectStatusSchema } from '../models/project.models.ts'
import { NonEmptyStringSchema } from '../models/property.models.ts'
import { ProjectDomainQueryModelSchema } from '../models/query.models.ts'

export const ProjectListItemServiceModelSchema =
  ProjectDomainQueryModelSchema.pick({
    projectId: true,
    name: true,
    description: true,
    dueDate: true,
    ownerId: true,
    brandTeamId: true,
    brandCompanyName: true,
    acceptorId: true,
    agencyTeamId: true,
    status: true,
    thumbnailFileId: true,
    lastTouchedTimestamp: true,
    cost: true,
    costPlusMargin: true,
  })
    .extend({
      package: ProjectDomainQueryModelSchema.shape.package
        .pick({
          packageId: true,
          name: true,
          cost: true,
          costPlusMargin: true,
        })
        .partial({ cost: true, costPlusMargin: true }),
      acl: z.object({
        canArchiveProject: z.boolean(),
      }),
    })
    .partial({
      cost: true,
      costPlusMargin: true,
    })

export type ProjectListItemServiceModel = z.infer<
  typeof ProjectListItemServiceModelSchema
>

const ProjectListAclSchema = z.object({
  canViewCostPlusMargin: z.boolean(),
  canViewCost: z.boolean(),
})

export type ProjectListAcl = z.infer<typeof ProjectListAclSchema>

export const ProjectListServiceModelSchema = z.object({
  acl: ProjectListAclSchema,
  items: z.array(ProjectListItemServiceModelSchema),
  // Add pagination metadata here (cursor, etc)
})

export type ProjectListServiceModel = z.infer<
  typeof ProjectListServiceModelSchema
>

export const ProjectListOrderByColumnSchema =
  ProjectDomainQueryModelSchema.keyof().extract([
    'status',
    'name',
    'package',
    'brandCompanyName',
    'lastTouchedTimestamp',
    'dueDate',
    'cost',
    'costPlusMargin',
  ])

export type ProjectListOrderByColumn = z.infer<
  typeof ProjectListOrderByColumnSchema
>

export const ProjectListOrderByDirectionSchema = z.enum(['asc', 'desc'])

export type ProjectListOrderByDirection = z.infer<
  typeof ProjectListOrderByDirectionSchema
>

export const ProjectListOrderBySchema = z.object({
  column: ProjectListOrderByColumnSchema,
  direction: ProjectListOrderByDirectionSchema,
})

export type ProjectListOrderBy = z.infer<typeof ProjectListOrderBySchema>

export const ProjectListWhereSchema = z.object({
  search: NonEmptyStringSchema().optional(),
  statuses: z.array(ProjectStatusSchema).nonempty().optional(),
})

export type ProjectListWhere = z.infer<typeof ProjectListWhereSchema>
