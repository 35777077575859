import { type Row, flexRender } from '@tanstack/react-table'

import { themeHoverBackgroundMap } from '@mntn-dev/ui-theme'

import { cn } from '@mntn-dev/ui-utilities'
import type { CSSProperties } from 'react'
import { Table } from './table.tsx'

export type DataTableRowProps<T> = {
  row: Row<T>
  data: T
  rowIdentifier: string
  onClick?: () => void
  tableId?: string
  style?: CSSProperties
}

export const DataTableRow = <T,>({
  row,
  onClick,
  style,
  tableId,
}: DataTableRowProps<T>) => (
  <Table.Row
    key={row.id}
    dataTestId={`${tableId}-table-row-${row.id}`}
    onClick={onClick}
    className={cn('h-18', themeHoverBackgroundMap.tertiary)}
    style={style}
  >
    {row.getVisibleCells().map((cell) => (
      <Table.Cell
        key={cell.id}
        className={cell.column.columnDef.meta?.className || ''}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Table.Cell>
    ))}
  </Table.Row>
)
