import type { PropsWithChildren } from 'react'
import { Text } from '../../text/text.tsx'

export type CardDescriptionProps = PropsWithChildren

const CardDescription = ({ children }: CardDescriptionProps) => (
  <Text fontSize="sm" textColor="secondary">
    {children}
  </Text>
)

export { CardDescription }
