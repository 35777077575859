import type { TupleToUnion } from 'type-fest'

import type { ThemeSpacing } from './spacing.ts'

const paddingMap: Record<ThemeSpacing, string> = {
  '0': 'p-0',
  px: 'p-px',
  '0.5': 'p-0.5',
  '1': 'p-1',
  '1.5': 'p-1.5',
  '2': 'p-2',
  '2.5': 'p-2.5',
  '3': 'p-3',
  '3.5': 'p-3.5',
  '4': 'p-4',
  '5': 'p-5',
  '6': 'p-6',
  '7': 'p-7',
  '8': 'p-8',
  '9': 'p-9',
  '10': 'p-10',
  '11': 'p-11',
  '12': 'p-12',
  '14': 'p-14',
  '16': 'p-16',
  '20': 'p-20',
  '24': 'p-24',
  '28': 'p-28',
  '32': 'p-32',
  '36': 'p-36',
  '40': 'p-40',
  '44': 'p-44',
  '48': 'p-48',
  '52': 'p-52',
  '56': 'p-56',
  '60': 'p-60',
  '64': 'p-64',
  '72': 'p-72',
  '80': 'p-80',
  '96': 'p-96',
  '112': 'p-112',
  '128': 'p-128',
  '144': 'p-144',
  '160': 'p-160',
  '176': 'p-176',
  '192': 'p-192',
  '222': 'p-222',
}

const paddingXMap: Record<ThemeSpacing, string> = {
  '0': 'px-0',
  px: 'px-px',
  '0.5': 'px-0.5',
  '1': 'px-1',
  '1.5': 'px-1.5',
  '2': 'px-2',
  '2.5': 'px-2.5',
  '3': 'px-3',
  '3.5': 'px-3.5',
  '4': 'px-4',
  '5': 'px-5',
  '6': 'px-6',
  '7': 'px-7',
  '8': 'px-8',
  '9': 'px-9',
  '10': 'px-10',
  '11': 'px-11',
  '12': 'px-12',
  '14': 'px-14',
  '16': 'px-16',
  '20': 'px-20',
  '24': 'px-24',
  '28': 'px-28',
  '32': 'px-32',
  '36': 'px-36',
  '40': 'px-40',
  '44': 'px-44',
  '48': 'px-48',
  '52': 'px-52',
  '56': 'px-56',
  '60': 'px-60',
  '64': 'px-64',
  '72': 'px-72',
  '80': 'px-80',
  '96': 'px-96',
  '112': 'px-112',
  '128': 'px-128',
  '144': 'px-144',
  '160': 'px-160',
  '176': 'px-176',
  '192': 'px-192',
  '222': 'px-222',
}

const paddingYMap: Record<ThemeSpacing, string> = {
  '0': 'py-0',
  px: 'py-px',
  '0.5': 'py-0.5',
  '1': 'py-1',
  '1.5': 'py-1.5',
  '2': 'py-2',
  '2.5': 'py-2.5',
  '3': 'py-3',
  '3.5': 'py-3.5',
  '4': 'py-4',
  '5': 'py-5',
  '6': 'py-6',
  '7': 'py-7',
  '8': 'py-8',
  '9': 'py-9',
  '10': 'py-10',
  '11': 'py-11',
  '12': 'py-12',
  '14': 'py-14',
  '16': 'py-16',
  '20': 'py-20',
  '24': 'py-24',
  '28': 'py-28',
  '32': 'py-32',
  '36': 'py-36',
  '40': 'py-40',
  '44': 'py-44',
  '48': 'py-48',
  '52': 'py-52',
  '56': 'py-56',
  '60': 'py-60',
  '64': 'py-64',
  '72': 'py-72',
  '80': 'py-80',
  '96': 'py-96',
  '112': 'py-112',
  '128': 'py-128',
  '144': 'py-144',
  '160': 'py-160',
  '176': 'py-176',
  '192': 'py-192',
  '222': 'py-222',
}

const paddingTopMap: Record<ThemeSpacing, string> = {
  '0': 'pt-0',
  px: 'pt-px',
  '0.5': 'pt-0.5',
  '1': 'pt-1',
  '1.5': 'pt-1.5',
  '2': 'pt-2',
  '2.5': 'pt-2.5',
  '3': 'pt-3',
  '3.5': 'pt-3.5',
  '4': 'pt-4',
  '5': 'pt-5',
  '6': 'pt-6',
  '7': 'pt-7',
  '8': 'pt-8',
  '9': 'pt-9',
  '10': 'pt-10',
  '11': 'pt-11',
  '12': 'pt-12',
  '14': 'pt-14',
  '16': 'pt-16',
  '20': 'pt-20',
  '24': 'pt-24',
  '28': 'pt-28',
  '32': 'pt-32',
  '36': 'pt-36',
  '40': 'pt-40',
  '44': 'pt-44',
  '48': 'pt-48',
  '52': 'pt-52',
  '56': 'pt-56',
  '60': 'pt-60',
  '64': 'pt-64',
  '72': 'pt-72',
  '80': 'pt-80',
  '96': 'pt-96',
  '112': 'pt-112',
  '128': 'pt-128',
  '144': 'pt-144',
  '160': 'pt-160',
  '176': 'pt-176',
  '192': 'pt-192',
  '222': 'pt-222',
}

const paddingRightMap: Record<ThemeSpacing, string> = {
  '0': 'pr-0',
  px: 'pr-px',
  '0.5': 'pr-0.5',
  '1': 'pr-1',
  '1.5': 'pr-1.5',
  '2': 'pr-2',
  '2.5': 'pr-2.5',
  '3': 'pr-3',
  '3.5': 'pr-3.5',
  '4': 'pr-4',
  '5': 'pr-5',
  '6': 'pr-6',
  '7': 'pr-7',
  '8': 'pr-8',
  '9': 'pr-9',
  '10': 'pr-10',
  '11': 'pr-11',
  '12': 'pr-12',
  '14': 'pr-14',
  '16': 'pr-16',
  '20': 'pr-20',
  '24': 'pr-24',
  '28': 'pr-28',
  '32': 'pr-32',
  '36': 'pr-36',
  '40': 'pr-40',
  '44': 'pr-44',
  '48': 'pr-48',
  '52': 'pr-52',
  '56': 'pr-56',
  '60': 'pr-60',
  '64': 'pr-64',
  '72': 'pr-72',
  '80': 'pr-80',
  '96': 'pr-96',
  '112': 'pr-112',
  '128': 'pr-128',
  '144': 'pr-144',
  '160': 'pr-160',
  '176': 'pr-176',
  '192': 'pr-192',
  '222': 'pr-222',
}

const paddingBottomMap: Record<ThemeSpacing, string> = {
  '0': 'pb-0',
  px: 'pb-px',
  '0.5': 'pb-0.5',
  '1': 'pb-1',
  '1.5': 'pb-1.5',
  '2': 'pb-2',
  '2.5': 'pb-2.5',
  '3': 'pb-3',
  '3.5': 'pb-3.5',
  '4': 'pb-4',
  '5': 'pb-5',
  '6': 'pb-6',
  '7': 'pb-7',
  '8': 'pb-8',
  '9': 'pb-9',
  '10': 'pb-10',
  '11': 'pb-11',
  '12': 'pb-12',
  '14': 'pb-14',
  '16': 'pb-16',
  '20': 'pb-20',
  '24': 'pb-24',
  '28': 'pb-28',
  '32': 'pb-32',
  '36': 'pb-36',
  '40': 'pb-40',
  '44': 'pb-44',
  '48': 'pb-48',
  '52': 'pb-52',
  '56': 'pb-56',
  '60': 'pb-60',
  '64': 'pb-64',
  '72': 'pb-72',
  '80': 'pb-80',
  '96': 'pb-96',
  '112': 'pb-112',
  '128': 'pb-128',
  '144': 'pb-144',
  '160': 'pb-160',
  '176': 'pb-176',
  '192': 'pb-192',
  '222': 'pb-222',
}

const paddingLeftMap: Record<ThemeSpacing, string> = {
  '0': 'pl-0',
  px: 'pl-px',
  '0.5': 'pl-0.5',
  '1': 'pl-1',
  '1.5': 'pl-1.5',
  '2': 'pl-2',
  '2.5': 'pl-2.5',
  '3': 'pl-3',
  '3.5': 'pl-3.5',
  '4': 'pl-4',
  '5': 'pl-5',
  '6': 'pl-6',
  '7': 'pl-7',
  '8': 'pl-8',
  '9': 'pl-9',
  '10': 'pl-10',
  '11': 'pl-11',
  '12': 'pl-12',
  '14': 'pl-14',
  '16': 'pl-16',
  '20': 'pl-20',
  '24': 'pl-24',
  '28': 'pl-28',
  '32': 'pl-32',
  '36': 'pl-36',
  '40': 'pl-40',
  '44': 'pl-44',
  '48': 'pl-48',
  '52': 'pl-52',
  '56': 'pl-56',
  '60': 'pl-60',
  '64': 'pl-64',
  '72': 'pl-72',
  '80': 'pl-80',
  '96': 'pl-96',
  '112': 'pl-112',
  '128': 'pl-128',
  '144': 'pl-144',
  '160': 'pl-160',
  '176': 'pl-176',
  '192': 'pl-192',
  '222': 'pl-222',
}

const paddingNames = [
  'padding',
  'paddingX',
  'paddingY',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
] as const
type PaddingName = TupleToUnion<typeof paddingNames>

type ThemePadding = Partial<Record<PaddingName, ThemeSpacing>>

const paddingMapMap: Record<PaddingName, Record<ThemeSpacing, string>> = {
  padding: paddingMap,
  paddingX: paddingXMap,
  paddingY: paddingYMap,
  paddingTop: paddingTopMap,
  paddingRight: paddingRightMap,
  paddingBottom: paddingBottomMap,
  paddingLeft: paddingLeftMap,
}

const getPaddingMap = (propName: PaddingName) => paddingMapMap[propName]

export {
  getPaddingMap,
  paddingBottomMap,
  paddingLeftMap,
  paddingMap,
  paddingNames,
  paddingRightMap,
  paddingTopMap,
  paddingXMap,
  paddingYMap,
  type ThemePadding,
}
