import { type Locale, format as formatAbsolute, formatRelative } from 'date-fns'

export type DateFormat =
  | 'long-date'
  | 'medium-date'
  | 'medium-date-alt'
  | 'medium-date-time'
  | 'short-time'
  | 'iso-like'
  | 'relative-time'

const dateFormatMap: Record<DateFormat, string> = {
  'long-date': 'MMMM d, y', // November 6, 2024
  'medium-date': 'MMM d, y', // Nov 6, 2024
  'medium-date-alt': 'dd MMM yyyy', // 06 Nov 2024
  'medium-date-time': 'MMM d, yyyy • h:mm a', // Nov 6, 2024 • 1:34 PM
  'short-time': 'h:mm a', // 1:34 PM
  'iso-like': 'yyyy:MM:dd HH:mm:ss', // 2024:11:06 13:34:00
  'relative-time': 'relative', // [last {day} | yesterday | today | tomorrow | next {day}] at h:mm a || MM/DD/YYYY
}

export const formatDate = (
  date: Date | undefined,
  format: DateFormat,
  locale?: Locale
) =>
  date
    ? format === 'relative-time'
      ? formatRelative(date, Date.now(), { locale })
      : formatAbsolute(date, dateFormatMap[format], { locale })
    : 'N/A'
