'use client'
import { getTestProps, type TestIds } from '../../utils/testing.ts'
import { Text } from '../text/text'
import { CollapsibleButton } from './collapsible-button.tsx'
import { Avatar } from '../avatar'
import type { Person } from '../../types'
import { type AbstractImage, cn } from '@mntn-dev/ui-utilities'
import { Stack } from '../stack'
import { useCollapsibleContext } from './use-collapsible.ts'
import { easeOut, motion } from 'framer-motion'

type CollapsibleCommentProps = {
  comment: string
  user: Person
  image: AbstractImage
  title?: string
} & TestIds

const CollapsibleComment = ({
  comment,
  user,
  image,
  title,
  dataTestId,
  dataTrackingId,
}: CollapsibleCommentProps) => {
  const { isOpen } = useCollapsibleContext()

  return (
    <div
      className="px-8 py-4"
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      <CollapsibleButton
        dataTestId={`${dataTestId}-button`}
        dataTrackingId={`${dataTrackingId}-button`}
      >
        <motion.div
          initial={{ opacity: 0, height: 0, overflow: 'hidden' }}
          animate={{ opacity: 1, height: 'fit-content' }}
          exit={{ opacity: 0, height: 0, overflow: 'hidden' }}
          transition={{ duration: 0.3, ease: easeOut }}
        >
          <Stack
            justifyContent="center"
            alignItems="start"
            gap="8"
            alignSelf="stretch"
          >
            <Avatar
              size="sm"
              className={cn(
                'transition-all duration-200 ease-in-out',
                isOpen && 'scale-125'
              )}
              dataTestId={`${dataTestId}-avatar`}
              dataTrackingId={`${dataTrackingId}-avatar`}
            >
              <Avatar.User person={user} image={image} />
            </Avatar>
            <Stack
              direction="col"
              width="full"
              justifyContent="center"
              className="text-left overflow-hidden"
            >
              <motion.div
                key="title-area"
                initial={{
                  opacity: 0,
                  height: 0,
                  paddingTop: 8,
                  overflow: 'hidden',
                }}
                animate={
                  isOpen
                    ? { opacity: 1, height: 'auto', paddingTop: 0 }
                    : { opacity: 0, height: 0, paddingTop: 8 }
                }
                transition={{ duration: 0.3, ease: easeOut }}
                style={{ overflow: 'hidden' }}
              >
                <Stack direction="col">
                  {title && (
                    <Text textColor="negative" fontSize="sm">
                      {title}
                    </Text>
                  )}
                  <Text textColor="primary" fontSize="med">
                    {user.displayName}
                  </Text>
                </Stack>
              </motion.div>
              <Text
                textColor="secondary"
                fontSize="med"
                className={cn(!isOpen && 'truncate whitespace-nowrap')}
              >
                {comment}
              </Text>
            </Stack>
          </Stack>
        </motion.div>
      </CollapsibleButton>
    </div>
  )
}

export { CollapsibleComment }
