'use client'

import {
  themeBackgroundBlurMap,
  themeBackgroundMap,
  themeBorderColorMap,
  themeDivideColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import {
  type ChangeEvent,
  type KeyboardEvent,
  type ReactNode,
  forwardRef,
  useMemo,
  useState,
} from 'react'
import type { UseFormFieldControlProps } from '../form-field/use-form-field-control.ts'
import { Icon } from '../icon/icon.tsx'
import { Input } from '../input/input.tsx'
import { Stack } from '../stack/stack.tsx'
import {
  MultiselectChips,
  type MultiselectChipsProps,
} from './multiselect-chips.tsx'
import { MultiselectInlineOption } from './multiselect-inline-option.tsx'
import {
  type MultiselectOptionItem,
  MultiselectProvider,
  type UseMultiselectProps,
  useMultiselect,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectInlineInnerProps = UseFormFieldControlProps<HTMLDivElement> &
  Pick<MultiselectChipsProps, 'placeholder'> & {
    emptyState?: ReactNode
    items?: MultiselectOptionItem[]
    onSearch?: (searchText: string) => void
  }

type MultiselectInlineProps = Readonly<
  UseMultiselectProps & MultiselectInlineInnerProps
>

const MultiselectInlineInner = forwardRef<
  HTMLDivElement,
  MultiselectInlineInnerProps
>(
  (
    {
      emptyState,
      items,
      placeholder,
      onSearch: onSearchProp,
      ...useFormFieldProps
    },
    ref
  ) => {
    const { isItemVisible, onSearch } = useMultiselectContext()
    const [searchText, setSearchText] = useState('')
    const [active, setActive] = useState(false)

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
      setActive(false)
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()

        onSearch(searchText)
        onSearchProp?.(searchText)

        setActive(true)
      }

      if (e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()

        handleClearSearch()
      }
    }

    const handleClearSearch = () => {
      setSearchText('')
      setActive(false)
    }

    const visibleItems = useMemo(
      () => items?.filter((item) => isItemVisible(item)) ?? [],
      [isItemVisible, items]
    )

    return (
      <Stack width="full" height="full" gap="2">
        <Stack direction="col" gap="2" width="1/2">
          <Input
            iconRight={
              active ? (
                <Icon
                  name="XMarkIcon"
                  color="info"
                  size="lg"
                  fill="outline"
                  onClick={handleClearSearch}
                />
              ) : (
                <Icon
                  name="MagnifyingGlassIcon"
                  color="info"
                  size="lg"
                  fill="outline"
                />
              )
            }
            placeholder="Search"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={searchText}
          />
          {visibleItems.length > 0 ? (
            <div
              className={cn(
                'rounded border divide-y overflow-x-hidden overflow-y-auto',
                themeBackgroundBlurMap['blur-xl'],
                themeBackgroundMap['container-secondary'],
                themeBorderColorMap.muted,
                themeDivideColorMap.muted
              )}
            >
              {visibleItems.map((item) => (
                <MultiselectInlineOption key={item.id} item={item} />
              ))}
            </div>
          ) : (
            emptyState
          )}
        </Stack>
        <Stack direction="col" width="1/2">
          <MultiselectChips
            className="h-full items-start"
            ref={ref}
            placeholder={placeholder}
            {...useFormFieldProps}
          />
        </Stack>
      </Stack>
    )
  }
)

const MultiselectInlineComponent = forwardRef<
  HTMLDivElement,
  MultiselectInlineProps
>(({ selectedItems, onChange, ...innerProps }, ref) => {
  const context = useMultiselect({ selectedItems, onChange })

  return (
    <MultiselectProvider value={context}>
      <MultiselectInlineInner {...innerProps} ref={ref} />
    </MultiselectProvider>
  )
})

const MultiselectInlineNamespace = Object.assign(MultiselectInlineComponent, {
  Option: MultiselectInlineOption,
})

export { MultiselectInlineNamespace as MultiselectInline }
