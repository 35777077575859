import { z } from 'zod'

import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  CompanyNameSchema,
  EmailAddressSchema,
  NonEmptyStringSchema,
  USPhoneNumberSchema,
  WebsiteUrlSchema,
} from './property.models.ts'
import {
  AgencyIdSchema,
  AgencyProfileIdSchema,
  AgencyUrnSchema,
  FileIdSchema,
} from './unique-id.models.ts'

/**
 * AgencyProfileDomainSelectModel
 */
export const AgencyProfileDomainSelectModelSchema = z.object({
  agencyProfileId: AgencyProfileIdSchema,
  agencyId: AgencyIdSchema,
  overview: NonEmptyStringSchema(4096).optional(),
  emailAddresses: EmailAddressSchema.array().nonempty(),
  websiteUrls: WebsiteUrlSchema.array().nonempty().optional(),
  phoneNumbers: USPhoneNumberSchema.array().nonempty().optional(),
  logoFileId: FileIdSchema.optional(),
})

export type AgencyProfileDomainSelectModel = z.infer<
  typeof AgencyProfileDomainSelectModelSchema
>

/**
 * AgencyProfileDomainInsertModel
 */
export const AgencyProfileDomainUpsertModelSchema =
  AgencyProfileDomainSelectModelSchema.omit({
    agencyProfileId: true,
  })

export type AgencyProfileDomainUpsertModel = z.infer<
  typeof AgencyProfileDomainUpsertModelSchema
>

/**
 * AgencyDomainSelectModel
 */
export const AgencyDomainSelectModelSchema = z.object({
  agencyId: AgencyIdSchema,
  agencyUrn: AgencyUrnSchema,
  companyName: CompanyNameSchema,
  companyWebsiteUrl: WebsiteUrlSchema.optional(),
})

export type AgencyDomainSelectModel = z.infer<
  typeof AgencyDomainSelectModelSchema
>

export const isAgencyDomainSelectModel = (
  value: unknown
): value is AgencyDomainSelectModel => {
  return AgencyDomainSelectModelSchema.safeParse(value).success
}

/**
 * AgencyDomainInsertModel
 */
export const AgencyDomainInsertModelSchema = AgencyDomainSelectModelSchema.omit(
  { agencyUrn: true }
).partial({
  agencyId: true,
})

export type AgencyDomainInsertModel = z.infer<
  typeof AgencyDomainInsertModelSchema
>

/**
 * AgencyDomainUpdateModel
 */
export const AgencyDomainUpdateModelSchema = AgencyDomainSelectModelSchema.omit(
  {
    agencyId: true,
    agencyUrn: true,
  }
)
  .extend({
    companyWebsiteUrl:
      AgencyDomainSelectModelSchema.shape.companyWebsiteUrl.nullable(),
  })
  .partial()

export type AgencyDomainUpdateModel = z.infer<
  typeof AgencyDomainUpdateModelSchema
>

/**
 * AgencyDomainSelectModelFilters
 */
export const AgencyDomainSelectModelFiltersSchema = z
  .object({
    agencyId: ModelFilterSchema(AgencyIdSchema),
    // When filtering, transform company names to lower case so the
    // "lower(agencies.company_name)" index can be used.
    companyName: ModelFilterSchema(CompanyNameSchema.toLowerCase()),
    search: NonEmptyStringSchema(),
  })
  .partial()

export type AgencyDomainSelectModelFilters = z.infer<
  typeof AgencyDomainSelectModelFiltersSchema
>
