'use client'

import { createContext } from '../utils/context.ts'
import type { TestIds } from '../utils/testing.ts'

export function useTestTags(props: TestIds) {
  return props
}

export const [TestTagsProvider, useTestTagsContext] = createContext<
  ReturnType<typeof useTestTags>
>({ name: 'TestTagsContext' })
