'use client'

import type { PropsWithChildren, ReactNode } from 'react'

import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import { Breadcrumbs } from '../../breadcrumbs/breadcrumbs.tsx'
import { Heading } from '../../heading/heading.tsx'

export const PageHeaderTitleBreadcrumbs = ({
  title,
  crumbs,
}: PropsWithChildren<{
  title: string
  crumbs?: ReactNode[]
}>) => {
  const { dataTestId, dataTrackingId } = useTestTagsContext()
  return (
    <Breadcrumbs divider="/" dividerSize="2xl">
      <Heading
        dataTestId={`${dataTestId}-title`}
        dataTrackingId={`${dataTrackingId}-title`}
        className="header-title"
      >
        {title}
      </Heading>
      {crumbs?.map((crumb) =>
        typeof crumb === 'string' ? (
          <Heading
            key={`page-header-breadcrumb-${crumb}`}
            textColor="secondary"
            fontSize="2xl"
            className="inline"
            dataTestId={`${dataTestId}-title-breadcrumb-${crumb}`}
            dataTrackingId={`${dataTrackingId}-title-breadcrumb-${crumb}`}
          >
            {crumb}
          </Heading>
        ) : (
          crumb
        )
      )}
    </Breadcrumbs>
  )
}
