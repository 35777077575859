'use client'

import type { ButtonProps } from '../buttons/button.tsx'
import { IconButton } from '../buttons/icon-button.tsx'
import { type AudioPlayerStatus, audioIconByStatusMap } from './types.tsx'

type Props = Readonly<
  ButtonProps & {
    status: AudioPlayerStatus
    onClick: () => void
  }
>

export const PlayerControlButton = ({ status, onClick }: Props) => (
  <button type="button" onClick={onClick}>
    <IconButton
      name={audioIconByStatusMap[status]}
      hoverColor="cta"
      size="5xl"
      color="info"
      strokeWidth="1"
    />
  </button>
)
