'use client'

import {
  type FontSize,
  type LineHeight,
  type ThemeSpacing,
  fontSizeMap,
  fontSizeToPixels,
  getLineHeightValue,
  lineHeightMap,
  paddingMap,
  spacingToPixels,
  themeBackgroundMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import {
  type ChangeEvent,
  type KeyboardEvent,
  forwardRef,
  useState,
} from 'react'
import {
  type VirtualItemProps,
  getVirtualItemProps,
} from '../../hooks/use-virtualizer.tsx'
import { Icon } from '../icon/icon.tsx'
import { useMultiselectContext } from './use-multiselect.ts'

type MultiselectSearchProps = Partial<VirtualItemProps> & {
  placeholder?: string
}

const inputLeading: LineHeight = '6'
const inputPadding: ThemeSpacing = '3'
const inputFontSize: FontSize = 'sm'

export const multiselectSearchHeight = () => {
  const { relative, value } = getLineHeightValue(inputLeading)
  const lineHeight = relative ? fontSizeToPixels(inputFontSize) * value : value

  return lineHeight + 2 * spacingToPixels(inputPadding)
}

const MultiselectSearch = forwardRef<HTMLDivElement, MultiselectSearchProps>(
  ({ placeholder, ...virtualProps }, ref) => {
    const { onSearch } = useMultiselectContext()
    const [searchValue, setSearchValue] = useState('')

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value)
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()

        onSearch(searchValue)
      }
    }

    return (
      <div
        className="relative overflow-hidden w-full"
        ref={ref}
        {...getVirtualItemProps(virtualProps)}
      >
        <input
          type="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={cn(
            fontSizeMap[inputFontSize],
            themeBackgroundMap.container,
            themeTextColorMap.primary,
            paddingMap[inputPadding],
            lineHeightMap[inputLeading],
            'pr-11 w-full border-none'
          )}
        />
        <Icon
          name="MagnifyingGlassIcon"
          size="md"
          color="info"
          className="absolute right-3 top-1/2 transform -translate-y-1/2"
        />
      </div>
    )
  }
)

export { MultiselectSearch }
