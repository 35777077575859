import type { z } from 'zod'
import { ProjectDomainSelectModelSchema } from '../project.models.ts'
import { CountSchema } from '../property.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectBiddingClosedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_bidding_closed',
    ProjectDomainSelectModelSchema.pick({
      projectId: true,
    })
      .extend({ bidCount: CountSchema })
      .merge(ActivityLinkTargets('project'))
  )

export type ProjectBiddingClosedActivityDetails = z.infer<
  typeof ProjectBiddingClosedActivityDetailsSchema
>
