import type { NonEmptyArray } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ProjectStatusSchema } from '../project.models.ts'
import { ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

// This is a subset of the project statuses since not all status changes require an activity
export const ProjectStatusChangedActivityStatusSchema =
  ProjectStatusSchema.extract([
    'bidding_open',
    'bidding_closed',
    'complete',
    'matched',
    'post_production_complete',
    'pre_production_complete',
    'priced',
    'pricing',
    'processing_final_files',
    'production',
    'submitted',
  ])

export type ProjectStatusChangedActivityStatus = z.infer<
  typeof ProjectStatusChangedActivityStatusSchema
>

const ProjectStatusChangedActivityDataSchema = <
  Status extends ProjectStatusChangedActivityStatus,
  Props extends z.ZodRawShape,
>(
  status: NonEmptyArray<Status>,
  props: Props
) => {
  return z
    .object({
      status: ProjectStatusChangedActivityStatusSchema.extract(status),
      projectId: ProjectIdSchema,
    })
    .extend(props)
    .merge(ActivityLinkTargets('project'))
}

export const ProjectStatusChangedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_status_changed',
    z.discriminatedUnion('status', [
      // Right now are the same, but in the future we may have different shapes for different statuses
      ProjectStatusChangedActivityDataSchema(
        ProjectStatusChangedActivityStatusSchema.options,
        {}
      ),
    ])
  )

export type ProjectStatusChangedActivityDetails = z.infer<
  typeof ProjectStatusChangedActivityDetailsSchema
>
