import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { NameSchema, WebsiteUrlSchema } from './property.models.ts'
import {
  OrganizationIdSchema,
  TeamIdSchema,
  TeamUrnSchema,
} from './unique-id.models.ts'

export const TeamDomainSelectModelSchema = z.object({
  teamId: TeamIdSchema,
  teamUrn: TeamUrnSchema,
  organizationId: OrganizationIdSchema,
  name: NameSchema,
  companyWebsiteUrl: WebsiteUrlSchema.optional(),
})

export type TeamDomainSelectModel = z.infer<typeof TeamDomainSelectModelSchema>

/**
 * TeamDomainInsertModel
 */
export const TeamDomainInsertModelSchema = TeamDomainSelectModelSchema.pick({
  teamId: true,
  organizationId: true,
  name: true,
  companyWebsiteUrl: true,
}).partial({
  teamId: true,
  companyWebsiteUrl: true,
})

export type TeamDomainInsertModel = z.infer<typeof TeamDomainInsertModelSchema>

/**
 * TeamDomainUpdateModel
 */
export const TeamDomainUpdateModelSchema = TeamDomainSelectModelSchema.pick({
  name: true,
  companyWebsiteUrl: true,
})
  .extend({
    companyWebsiteUrl:
      TeamDomainSelectModelSchema.shape.companyWebsiteUrl.nullable(),
  })
  .partial()

export type TeamDomainUpdateModel = z.infer<typeof TeamDomainUpdateModelSchema>

/**
 * TeamDomainSelectModelFilters
 */
export const TeamDomainSelectModelFiltersSchema = z
  .object({
    teamId: ModelFilterSchema(TeamIdSchema),
    organizationId: ModelFilterSchema(OrganizationIdSchema),
    // When filtering, transform names to lower case so the
    // "lower(teams.name)" index can be used.
    name: ModelFilterSchema(NameSchema.toLowerCase()),
  })
  .partial()

export type TeamDomainSelectModelFilters = z.infer<
  typeof TeamDomainSelectModelFiltersSchema
>
