'use client'

import {
  type Virtualizer,
  useVirtualizer as useTanstackVirtualizer,
} from '@tanstack/react-virtual'
import type { Provider } from 'react'
import {
  createContext,
  getTypedProvider,
  getTypedRequiredUseContext,
} from '../utils/context.ts'

export type UseVirtualizerProps<
  ScrollElement extends Element,
  ItemElement extends Element,
> = Parameters<typeof useTanstackVirtualizer<ScrollElement, ItemElement>>[0]

function useVirtualizerInstance<
  ScrollElement extends Element,
  ItemElement extends Element,
>(props: UseVirtualizerProps<ScrollElement, ItemElement>) {
  return useTanstackVirtualizer<ScrollElement, ItemElement>(props)
}

type UseVirtualizerInstanceReturn<
  ScrollElement extends Element,
  ItemElement extends Element,
> = ReturnType<typeof useVirtualizerInstance<ScrollElement, ItemElement>>

type UseVirtualizerReturn<
  ScrollElement extends Element,
  ItemElement extends Element,
> = [
  UseVirtualizerInstanceReturn<ScrollElement, ItemElement>,
  Provider<Virtualizer<ScrollElement, ItemElement>>,
  boolean,
]
export function useVirtualizer<
  ScrollElement extends Element,
  ItemElement extends Element,
>(props: UseVirtualizerProps<ScrollElement, ItemElement>) {
  const virtualizerInstance = useVirtualizerInstance<
    ScrollElement,
    ItemElement
  >(props)

  return [
    virtualizerInstance,
    getTypedProvider<Virtualizer<ScrollElement, ItemElement>>(
      UntypedVirtualizationProvider
    ),
    props.enabled ?? false,
  ] as UseVirtualizerReturn<ScrollElement, ItemElement>
}

export type VirtualItemProps = {
  index: number
}

export const getVirtualItemProps = (props?: Partial<VirtualItemProps>) => {
  if (props?.index === undefined) {
    return {}
  }

  return {
    'data-index': props.index,
  }
}

const [UntypedVirtualizationProvider, useUntypedVirtualizationContext] =
  createContext<UseVirtualizerInstanceReturn<Element, Element>>({
    name: 'VirtualizationContext',
  })

export function useVirtualizationContext<
  ScrollElement extends Element,
  ItemElement extends Element,
>() {
  return getTypedRequiredUseContext<
    UseVirtualizerInstanceReturn<ScrollElement, ItemElement>
  >(useUntypedVirtualizationContext)()
}
